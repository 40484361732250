<style>
    .button-reset-file{
        width: fit-content;
        height: fit-content;
        top: 50%;
        position: absolute;
        right: 12px;
        z-index: 2;
        transform: translateY(-50%);
        line-height: 14px;
    }
</style>
<div class="w-full h-auto flex flex-row justify-content-start align-items-center">
    <div class="relative mr-2" [style]="{'width': (options.isShowButtonUpload?'80%':'100%')}">
        <div class="h-full w-full absolute top-0 left-0 z-1 opacity-0">
            <input 
                type="file" 
                [(ngModel)]="formObject.file" 
                class="h-full w-full"
                [class]="options.disabled?'':'cursor-pointer'"
                (change)="changeFile($event)"
                [disabled]="options.disabled" [accept]="getTypeFileAccept()"
            />
        </div>
        <div [class]="options.disabled?'bg-black-alpha-10':''"  class="w-full border-1 border-black-alpha-40 border-round border-dotted flex flex-row justify-content-center align-items-center" style="box-sizing: border-box;min-height: 42px;">
            <div class="max-w-full overflow-hidden text-overflow-ellipsis p-2 pl-4 pr-4 white-space" style="box-sizing: border-box;">
                {{fileObject?textDescription:tranService.translate("global.button.uploadFile")}}
            </div>
        </div>
        <div *ngIf="fileObject != null && !options.disabled" class="cursor-pointer button-reset-file" (click)="resetFile()">
            <i class="pi pi-times"></i>
        </div>
    </div>
    <div *ngIf="options.isShowButtonUpload" class="" style="width: 20%;">
        <button [disabled]="invalid || fileObject == null || options.disabled" icon="pi pi-upload" pButton type="button" [pTooltip]="tranService.translate('global.button.upFile')" class="p-button-outlined p-button-secondary" (click)="upload()"></button>
    </div>
</div>
<div>
    <small class="text-red-500" *ngIf="invalid =='required'">{{tranService.translate("global.message.required")}}</small>
    <small class="text-red-500" *ngIf="invalid =='maxsize'">{{tranService.translate("global.message.maxsizeupload",{len:50})}}</small>
    <small class="text-red-500" *ngIf="invalid =='invalidtype'">{{options.messageErrorType ? options.messageErrorType : tranService.translate("global.message.invalidtypeupload")}}</small>
</div>