import { Inject, Injectable } from "@angular/core";
import { HttpService } from "../comon/http.service";

@Injectable({ providedIn: 'root'})
export class ProjectService{
    private prefixApi: string;
    constructor(@Inject(HttpService) private httpService:HttpService) {
        this.prefixApi = "/projects";
    }

    search(params, callback, errorCallback, finallyCallback){
        this.httpService.get(`${this.prefixApi}/search`, {}, params, callback, errorCallback, finallyCallback);
    }

    create(data, callback, errorCallback, finallyCallback){
        this.httpService.post(`${this.prefixApi}`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    update(projectId, data, callback, errorCallback, finallyCallback){
        this.httpService.put(`${this.prefixApi}/${projectId}`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    detail(projectId, callback, errorCallback, finallyCallback){
        this.httpService.get(`${this.prefixApi}/${projectId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    delete(projectId, callback, errorCallback, finallyCallback){
        this.httpService.delete(`${this.prefixApi}/${projectId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    checkExist(name, khoaHocId, linhVucId, callback){
        this.httpService.get(`${this.prefixApi}/checkExist`, {}, {name,linhVucId,khoaHocId}, callback);
    }

    upload(objectFile, callback, errorCallback, finallyCallback){
        this.httpService.upload(`/media/upload`, objectFile, {}, {}, callback, errorCallback, finallyCallback);
    }

    download(fileId){
        this.httpService.download(`/media/download/${fileId}`, {}, {});
    }

    getResource(fileId, callback){
        this.httpService.download(`/media/download/${fileId}`, {}, {}, callback);
    }

    changeActive(projectId, callback){
        this.httpService.put(`${this.prefixApi}/active/${projectId}`, {}, {}, {}, callback);
    }

    registerProject(projectId, callback, errorCallBack, finallyCallback){
        this.httpService.download(`${this.prefixApi}/register/${projectId}`, {}, {}, callback, errorCallBack, finallyCallback);
    }

    submitProject(projectId, fileObject, callback, errorCallBack, finallyCallback){
        this.httpService.upload(`${this.prefixApi}/submit/${projectId}`, fileObject, {}, {}, callback, errorCallBack, finallyCallback);
    }

    searchHistory(params, callback, errorCallback, finallyCallback){
        this.httpService.get(`${this.prefixApi}/history`, {}, params, callback, errorCallback, finallyCallback);
    }
}