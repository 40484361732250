import { AfterContentChecked, Component, ElementRef, Injector, Input, OnInit } from "@angular/core";
import { ComponentBase } from "src/app/component.base";
import { CONSTANTS } from "src/app/service/comon/constants";

export class CaptchaController {
    reload: Function = () => {

    }
}

@Component({
    selector: 'captcha-custom',
    templateUrl: "./captcha.html",
    styleUrls: ["./captcha.css"]
})
export class CaptchaComponent extends ComponentBase implements OnInit {
    @Input() callBackSuccess: Function;
    @Input() controller!: CaptchaController;
    constructor(injector: Injector, private eRef: ElementRef) {
        super(injector);
    }

    folderImage: string = "/assets/demo/images/captcha";
    imagePath: string;
    styleImage: any = {};

    oldX: number;
    hasHandleMove: boolean = false;
    positionLeftOfButtonMove: number = 0;

    positionLeftOfBoxFill: number = 0;
    styleBoxEmpty:any = {};
    styleBoxFill: any = {};

    ngOnInit(): void {
        this.controller.reload = this.reloadCaptcha.bind(this);
        this.initCaptcha();
        let me = this;
        window.onmouseup = (event) => {
            if(me.hasHandleMove){
                me.checkMatch();
            }
            me.hasHandleMove = false;
        }
    }

    reloadCaptcha(){
        let me = this;
        setTimeout(() => {
            me.initCaptcha();
        })
    }

    initCaptcha(){
        this.positionLeftOfBoxFill = 0;
        this.positionLeftOfButtonMove = 0;
        let index = Math.floor(Math.random() * CONSTANTS.NUMBER_IMAGE_CAPTCHA) + 1;
        this.imagePath = this.folderImage + "/image" + index + ".png";
        this.styleImage = {
            "background-image": `url(${this.imagePath})`
        }
        //random position
        let mainImage = this.eRef.nativeElement.querySelector(".mainImage");
        let widthBox = this.eRef.nativeElement.querySelector(".box-empty").getBoundingClientRect().width;
        let heightBox = this.eRef.nativeElement.querySelector(".box-empty").getBoundingClientRect().height;
        let minWidth = mainImage.getBoundingClientRect().width / 2;
        let maxWidth = mainImage.getBoundingClientRect().width - widthBox;
        let maxHeight = mainImage.getBoundingClientRect().height - heightBox;

        let left = Math.floor(Math.random() * (maxWidth - minWidth)) + minWidth;
        let top = Math.floor(Math.random() * maxHeight);
        this.styleBoxFill = {
            "background-image": `url(${this.imagePath})`,
            "background-position": `-${left}px -${top}px`,
            top: `${top}px`,
            left: "0px",
        }
        this.styleBoxEmpty = {
            top: `${top}px`,
            left: `${left}px`,
        }
    }

    handleMouseDown(event){
        this.hasHandleMove = true;
        this.oldX = event.screenX;
    }

    handleMouseMove(event){
        if(this.hasHandleMove == true) {
            let delta = event.screenX - this.oldX;
            this.oldX = event.screenX;
            let newPosition = this.positionLeftOfButtonMove + delta;
            let mainImage:Element = this.eRef.nativeElement.querySelector(".mainImage");
            let boxEmpty: Element = this.eRef.nativeElement.querySelector(".box-empty");
            if(newPosition < 0){
                newPosition = 0;
            }else if(newPosition > mainImage.getBoundingClientRect().width - boxEmpty.getBoundingClientRect().width){
                newPosition = mainImage.getBoundingClientRect().width - boxEmpty.getBoundingClientRect().width;
            }
            this.positionLeftOfButtonMove = newPosition;
            this.positionLeftOfBoxFill = newPosition;
        }
    }

    checkMatch(){
        let boxEmpty: Element = this.eRef.nativeElement.querySelector(".box-empty");
        let boxFill: Element = this.eRef.nativeElement.querySelector(".box-fill ");
        let epsilon = 2;
        let xEmpty = boxEmpty.getBoundingClientRect().left;
        let yEmpty = boxEmpty.getBoundingClientRect().top;
        let xFill = boxFill.getBoundingClientRect().left;
        let yFill = boxFill.getBoundingClientRect().top;
        if(xFill >= xEmpty - epsilon && xFill <= xEmpty + epsilon){
            if(yFill >= yEmpty - epsilon && yFill <= yEmpty + epsilon){
                this.handleAfterComplete();
                return;
            }
        }
        this.messageCommonService.error(this.tranService.translate("global.message.errorMatchCaptcha"))
    }

    handleAfterComplete(){
        if(this.callBackSuccess != null && this.callBackSuccess != undefined){
            this.callBackSuccess();
        }
    }
}