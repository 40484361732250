import { Inject, Injectable } from "@angular/core";
import { HttpService } from "../comon/http.service";
import {Router} from "@angular/router";
import { ObservableService } from "../comon/observable.service";
import { CONSTANTS } from "../comon/constants";

@Injectable({ providedIn: 'root'})
export class SessionService{
    private authPrefixApi: string;
    private userPrefixApi: string;
    public userInfo:any;
    public token:string;
    public confirmPolicyHistory: Array<any>;
    constructor(@Inject(HttpService) private httpService:HttpService, private observableService: ObservableService,
                private router : Router) {
        this.authPrefixApi = "/auth";
        if(localStorage.getItem("userInfo")) {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        }else{
            this.userInfo = {};
        }
        if(localStorage.getItem("confirmPolicyHistory")) {
            this.confirmPolicyHistory = JSON.parse(localStorage.getItem("confirmPolicyHistory"));
        }else{
            this.confirmPolicyHistory = [];
        }
    }

    getData(key: string): string | undefined {
        return localStorage.getItem(key);
    }

    setData(key: string, data: string): void {
        localStorage.setItem(key, data);
    }

    updateToken(token) {
        this.httpService.updateHeader(token);
    }

    public login(body, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.postNoHeader(`${this.authPrefixApi}/login`, {}, body,{}, callback, errorCallBack, finallyCallback);
    }

    public logout(callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.get(`${this.authPrefixApi}/logout`,{}, {}, callback, errorCallback, finallyCallback);
    }

    public current(callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.get(`${this.authPrefixApi}/current`,{},{}, callback, errorCallBack, finallyCallback);
    }
}
