export default {
    label: {
        email: "Thư điện tử/Số điện thoại",
        password: "Mật khẩu",
        signIn : "Đăng nhập",
        forgotPass : "Quên mật khẩu",
        title : "Hệ thống Quản lý Đồ Án",
        resetPass : "Khôi phục mật khẩu",
        editProfile : "Thông tin tài khoản",
        changePassword : "Đổi mật khẩu",
        logout : "Đăng xuất",
        linkExpired: "Yêu cầu hết hạn"
    },
    text: {
     
    }
}