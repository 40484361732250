import { Injectable } from "@angular/core";
import { transData } from "src/i18n";
@Injectable({ providedIn: 'root'})
export class TranslateService{
    public lang: string;
    constructor(){
        this.lang = localStorage.getItem("lang") || "vi";
    }

    public translate(path: string, params?: {[key: string]: any}, textReplace?:string): string{
        try {
            let arrayPath = path.split("\.");
            let value = transData[this.lang];
            if(value === undefined || value === null){
                value = transData["vi"];
            }
            arrayPath.forEach(el => value = value[el]);
            if(typeof(value) === 'string'){
                if(params != undefined && params != null){
                    let keys = Object.keys(params);
                    keys.forEach(el => {
                        while(value.indexOf(`\$\{${el}\}`)>=0){
                            value = value.replace( `\$\{${el}\}`, params[el])
                        }
                    })
                }
                return value;
            }
        } catch (error) {
            // console.error(error);
        }
        if(textReplace){
            return textReplace;
        }else{
            return path;
        }
    }
}