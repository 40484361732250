import { AfterContentChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { CONSTANTS } from "src/app/service/comon/constants";
import { ObservableService } from "src/app/service/comon/observable.service";
import { TranslateService } from "src/app/service/comon/translate.service";
import { UtilService } from "src/app/service/comon/util.service";

export interface OptionInputFile {
    type?: Array<string>;//định dạng file cho phép
    maxSize?: number;//dung lượng tối đa cho phép
    unit?: "KB" | "MB" | "GB";//đơn vị tính dung lượng tối đa
    required?: boolean;//có bắt buộc hay không
    disabled?: boolean;//có disable hay không
    isShowButtonUpload?: boolean;// có hiển thị nút upload hay không
    actionUpload?: Function,// hành động khi upload là gì
    messageErrorType?:string//message nếu lỗi định dạnh là gì
}

@Component({
    selector: "input-file-custom",
    templateUrl: "./input.file.component.html"
})
export class InputFileCustomComponent implements OnInit, AfterContentChecked, OnDestroy{
    @Input() options!: OptionInputFile;// option input file
    @Input() fileObject!: any;// value input file
    @Input() clearFileCallback?: Function;// hàm xử lý sau khi clear value
    @Output() fileObjectChange: EventEmitter<any> = new EventEmitter();
    @Input() changeValue?: Function;
    constructor(
        public tranService: TranslateService,
        private formBuilder: FormBuilder,
        private utilService: UtilService,
        private observableService: ObservableService
    ){

    }

    formObject: {
        file: any
    }
    formInstance: any;
    textDescription: string | null;
    invalid: "required" | "maxsize" | "invalidtype" | null;
    hasreset: boolean = false;
    subsObsevable: any;
    ngOnInit(): void {
        this.formObject = {
            file: null
        }

        this.formInstance = this.formBuilder.group(this.formObject);
        this.textDescription = this.tranService.translate("global.button.uploadFile");
        this.subsObsevable = this.observableService.subscribe(CONSTANTS.OBSERVABLE.KEY_INPUT_FILE, {
            next: this.reset.bind(this)
        })
    }

    ngAfterContentChecked(): void {
    }

    changeFile(event){
        let file = event.target.files[0];
        this.fileObject = file;
        this.fileObjectChange.emit(this.fileObject);
        if(this.fileObject == null){
            this.textDescription = this.tranService.translate("global.button.uploadFile");
            this.checkValid();
            return;
        }
        let filename = file.name;
        let filesize = Math.round(file.size/1024);
        let suffix = "KB";
        if(filesize/1024 > 2){
            filesize = Math.round(filesize/1024);
            suffix = "MB";
        }
        this.textDescription = `${filename} ${this.utilService.convertNumberToString(filesize)}(${suffix})`
        this.checkValid();
    }

    resetFile(){
        this.formObject.file = null;
        this.textDescription = this.tranService.translate("global.button.uploadFile");
        this.fileObject = null;
        this.checkValid();
        if(this.clearFileCallback){
            this.clearFileCallback();
        }
    }

    checkValid(){
        this.invalid = null;
        if(this.fileObject){
            if(this.options.type){
                let extension = this.fileObject.name.substring(this.fileObject.name.lastIndexOf(".")+1, this.fileObject.name.length);
                if(!this.options.type.includes(extension)){
                    this.invalid = "invalidtype";
                }
            }
            if(this.options.maxSize && this.invalid == null){
                let comparesize = this.options.maxSize;
                if(this.options.unit == "KB"){
                    comparesize = comparesize * 1024;
                }else if(this.options.unit == "MB"){
                    comparesize = comparesize * 1024 * 1024;
                }else if(this.options.unit == "GB"){
                    comparesize = comparesize * 1024 * 1024 * 1024;
                }
                if(this.fileObject.size > comparesize){
                    this.invalid = "maxsize";
                }
            }
        }else{
            if(this.options.required){
                this.invalid = "required";
            }
        }
        if(this.changeValue){
            this.changeValue(this.invalid);
        }
    }

    reset(){
        this.formObject.file = null;
        this.fileObject = null;
        this.invalid = null;
        this.options.disabled = false;
    }

    upload(){
        this.options.actionUpload(this.fileObject);
    } 

    ngOnDestroy(): void {
        this.subsObsevable.unsubscribe();
    }

    getTypeFileAccept(){
        if(this.options.type){
            return this.options.type.map(n => `.${n}`).join(",")
        }else{
            return "*"
        }
    }
}