export default{
    
    status: {
        400: "Bad request",
        401: "Unauthorized",
        403: "Forbidden",
        404: "Not found",
        406: "Not Acceptable",
        408: "Request Timeout",
        409: "Conflict",
        500: "Internal Server Error"
    }
}
