import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({providedIn: 'root'})
export class RouterService {
    private router: Router;
    private route: ActivatedRoute;
    constructor(private injector: Injector) {
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
    }
    
    public getDataPage(){
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }
        return currentRoute.routeConfig.data;
    }
}