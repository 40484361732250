export default {
    label: {
        name: "Tên project",
        domain: "Lĩnh vực",
        course: "Khóa học",
        countStudentRegister: "Số lượng học viên đăng ký",
        uploadFileSource: "File source",
        uploadFileAvatar: "File avatar",
        active: "Mở đăng ký",
        description: "Mô tả",
        detailDataSource: "Chi tiết bộ dữ liệu",
        baiToan: "Bài toán",
        deadline: "Thời gian làm bài",
        descriptionShort: "Mô tả ngắn",
        expiredTime: "Hạn nộp",
        registerTime: "Ngày đăng ký",
        submitTime: "Ngày nộp bài"
    },
    text: {
        inputProjectName: "Nhập tên project",
        inputDeadline: "Nhập thời gian làm bài",
        selectCourse: "Chọn khóa học",
        selectDomain: "Chọn lĩnh vực",
        selectStudent: "Chọn học viên",
        selectProject: "Chọn project",
        selectRegisterProject: "Chọn đăng ký project",
        selectStudentRegister: "Chọn học viên đăng ký"
    }
}