<div class="captcha">
    <div class="relative w-full">
        <div class="button-reload-captcha" (click)="reloadCaptcha()"><span class="pi pi-replay" style="font-size: larger; font-weight: 900;color: black;"></span></div>
        <div class="mainImage" [style]="styleImage"></div>
        <div class="box-empty" [style]="styleBoxEmpty"></div>
        <div class="box-fill" [style]="styleBoxFill" [ngStyle]="{'left.px': positionLeftOfBoxFill}"></div>
    </div>
    <div class="relative w-full mt-2" (mousemove)="handleMouseMove($event)">
        <div class="button-move" [ngStyle]="{'left.px': positionLeftOfButtonMove}"
            (mousedown)="handleMouseDown($event)"
        >
            <span class="pi pi-arrow-right"></span>
        </div>
        <div class="tray-move">{{tranService.translate("global.text.textCaptcha")}}</div>
    </div>
</div>