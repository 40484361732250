import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { ComponentBase } from "src/app/component.base";
import { FormBuilder } from "@angular/forms";
import { AccountService } from "src/app/service/account/AccountService";
import { CourseService } from "src/app/service/course/course.service";
import { DomainService } from "src/app/service/domain/domain.service";
import { ProjectService } from "src/app/service/project/project.service";
import { YearCourseService } from "src/app/service/year-course/year.course.service";

@Component({
    selector: 'app-student-profile',
    templateUrl: './app.student.profile.component.html'
})
export class AppAccountProfileComponent extends ComponentBase implements OnInit{

    items: MenuItem[];
    home: MenuItem;

    student: {
        id: number | null,
        name: string | null,
        email: string | null,
        phone: string | null,
        nienKhoaId: number | null,
        khoaHocIds: number[] | null,
        hocVienKhoaHocs?: Array<any> | null,
        khoaHocId?: number | null
    } = {
        id: null,
        name: null,
        email: null,
        phone: null,
        nienKhoaId: null,
        khoaHocIds: null,
        khoaHocId: null,
    }
    studentOld: any;
    detailCourse: any;

    studentForm: any;
    isEmailExisted: boolean = false;
    isPhoneExisted: boolean = false;
    listNienKhoa: Array<any>;
    listCourse: Array<any>;

    userId: number;

    constructor(private accountService: AccountService,
                private yearCourseService: YearCourseService,
                private courseService: CourseService,
                private domainService: DomainService,
                private projectService: ProjectService,
                private eRef: ElementRef,
                private formBuilder: FormBuilder,
                private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        let me = this;
        this.items = [{ label: this.tranService.translate("global.text.accountInfo")}];
        this.home = { icon: 'pi pi-home', routerLink: '/' };

        this.yearCourseService.getAll((response) => {
            me.listNienKhoa = response;
        })
        this.courseService.getAll((response) => {
            me.listCourse = response;
        })
        this.userId = this.sessionService.userInfo.id;
        this.getOne();
    }

    getOne(){
        let me = this;
        this.accountService.getById(this.userId, (response)=>{
            me.student = response;
            me.studentOld = {...response};
            let dataForm = {
                id: response.id,
                name: response.name,
                email: response.email,
                phone: response.phone,
                nienKhoaId: response.nienKhoaId,
                khoaHocIds: null,
                khoaHocId: null
            }
            me.studentForm = me.formBuilder.group(dataForm);
            me.listCourse = me.student.hocVienKhoaHocs.map(el => {
                return {
                    id: el.khoaHocId,
                    name: el.khoaHocName
                }
            })
        })
    }

    onSubmitCreate(){
        let me = this;
        me.messageCommonService.onload();
        me.accountService.updateProfile(this.userId, this.student, (response)=>{
            me.messageCommonService.success(me.tranService.translate('global.message.success'));
        }, null, ()=> me.messageCommonService.offload())
    }

    checkExistAccount(key){
        let me = this;
        if(this.student[key] && this.student[key] != this.studentOld[key]){
            this.debounceService.set("checkAccount"+key, () => {
                me.accountService.checkAccount(key, this.student[key], (response)=>{
                    if(key == "email"){
                        if(response > 0){
                            me.isEmailExisted = true;
                        }else{
                            me.isEmailExisted = false;
                        }
                    }else if(key == "phone"){
                        if(response > 0){
                            me.isPhoneExisted = true;
                        }else{
                            me.isPhoneExisted = false;
                        }
                    }
                })
            })
        }
    }

    viewDetailCourse(){
        let me = this;
        this.detailCourse = this.student.hocVienKhoaHocs.filter(el => el.khoaHocId == me.student.khoaHocId)[0];
    }
}