import { Inject, Injectable } from "@angular/core";
import { HttpService } from "../comon/http.service";

@Injectable({ providedIn: 'root'})
export class AccountService{
    private prefixApi: string;
    constructor(@Inject(HttpService) private httpService:HttpService) {
        this.prefixApi = "/users";
    }

    public search(params:{[key: string]: string}, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.get(`${this.prefixApi}`, {}, params, callback, errorCallBack, finallyCallback);
    }

    public getById(id: number, callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.get(`${this.prefixApi}/${id}`,{}, {}, callback, errorCallback, finallyCallback);
    }

    public deleleUser(id: number, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.delete(`${this.prefixApi}/${id}`, {}, {}, callback, errorCallBack, finallyCallback);
    }

    public createAccount(body,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.post(`${this.prefixApi}`, {},body,{}, callback, errorCallback, finallyCallback);
    }

    public updateAccount(id,body,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.put(`${this.prefixApi}/${id}`, {},body,{}, callback, errorCallback, finallyCallback);
    }

    public checkAccount(key, value,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.get(`${this.prefixApi}/check-exist`, {},{[key]: value}, callback, errorCallback, finallyCallback);
    }

    public changePassword(body,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.post(`/auth/change-password`, {},body,{}, callback, errorCallback, finallyCallback);
    }

    public forgotPasswordInit(email: string,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.postNoHeader(`/auth/forgot-password/init?`,{}, {},{email}, callback, errorCallback, finallyCallback);
    }

    public forgotPasswordFinish(body,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.postNoHeader(`/auth/forgot-password/finish`,{}, body,{}, callback, errorCallback, finallyCallback);
    }

    public validateTokenEmail(body,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.postNoHeader(`/auth/validate-token-mail`,{}, body,{}, callback, errorCallback, finallyCallback);
    }

    public viewProfile(callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.get(`${this.prefixApi}/view-profile`,{}, {}, callback, errorCallback, finallyCallback);
    }

    public updateProfile(userId, body,callback?: Function, errorCallback?:Function, finallyCallback?: Function){
        this.httpService.put(`${this.prefixApi}/profile/${userId}`, {},body,{}, callback, errorCallback, finallyCallback);
    }

    public clearProject(userId, khoaHocId, callback?: Function, errorCallback?: Function, finallyCallback?: Function){
        this.httpService.delete(`${this.prefixApi}/clearProject/${userId}/${khoaHocId}`, {}, {}, callback, errorCallback, finallyCallback);
    }
}
