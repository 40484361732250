import {AfterContentChecked, Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService} from "src/app/service/account/AccountService";
import {DebounceInputService} from "src/app/service/comon/debounce.input.service";
import {MessageCommonService} from "src/app/service/comon/message-common.service";
import {TranslateService} from "src/app/service/comon/translate.service";
import {UtilService} from "src/app/service/comon/util.service";
import {SessionService} from "../../../service/session/SessionService";
import {FormBuilder} from "@angular/forms";

@Component({
    selector: "app-change-password",
    templateUrl: './app.change.password.html'
})
export class AppChangePasswordComponent implements OnInit, AfterContentChecked {
    constructor(private route: ActivatedRoute,
                private router: Router,
                public utilService: UtilService,
                public accountService: AccountService,
                public tranService: TranslateService,
                public messageCommonService: MessageCommonService,
                private debounceService: DebounceInputService,
                private sessionService: SessionService,
                private formBuilder: FormBuilder) {

    }

    formChangePass: any;
    changePassInfo: {
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    }
    items: any
    home: any
    isValidOldPass: boolean
    isShowOldPass: boolean = false;
    isShowNewPass: boolean = false;
    isShowRepeatPass: boolean = false;

    ngOnInit(): void {
        this.isValidOldPass = true
        this.changePassInfo = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
        this.formChangePass = this.formBuilder.group(this.changePassInfo);
        this.items = [
            {label: this.tranService.translate("global.text.accountInfo"), routerLink:"/profile"},
            {label: this.tranService.translate("global.text.changePass")}
        ];
        this.home = {icon: 'pi pi-home', routerLink: '/'};
    }

    ngAfterContentChecked(): void {

    }

    submitChangePass(oldPass: string) {
        this.messageCommonService.onload();
        let me = this;
        this.accountService.changePassword(this.changePassInfo,(response) => {
            me.messageCommonService.success(me.tranService.translate("global.message.saveSuccess"));
            this.router.navigate(["/profile"])
        }, null, ()=>{
            me.messageCommonService.offload();
        })
    }
}
