<div [class]="styleClass" [style]="style">
    <label *ngIf="floatLabel && isShowBoxSelect" class="float-label" [class]="control.invalid && control.dirty ? 'text-red-500' : ''">{{placeholder}}<span *ngIf="showTextRequired" class="text-red-500">*</span></label>
    <input type="text" class="inputTextHidden" style="height: 0; width: 0; position: absolute; z-index: -1;border: none;background-color: transparent;" (keydown)="controlByKey($event)">
    <div *ngIf="isAutoComplete == false" class="w-full bg-white flex flex-row justify-content-between align-items-center box-dropdown cursor-pointer" [class]="isShowBoxSelect == true ? 'box-focus' : (control.invalid && control.dirty ? 'box-invalid' : '')" (click)="openSugget()">
        <div *ngIf="isMultiChoice" class="flex-grow-1" style="max-width: calc(100% - 56px);">
            <div *ngIf="listObjectDisplay != null && listObjectDisplay != undefined && listObjectDisplay.length > 0 && listObjectDisplay.length <= maxDisplay" class="box-value-multi">
                <div *ngFor="let item of listObjectDisplay;let i = index" class="box-value-display" [class]="disabled == true ? 'box-value-display-disable' : ''">
                    <span *ngIf="!disabled" class="mr-2 pi pi-times" [target]="id" (click)="toggeSelectRow('', item)"></span>
                    <div class="value-display" [title]="getTextItemDisplay(item)">{{getTextItemDisplay(item)}}&nbsp;</div>
                </div>
            </div>
            <div *ngIf="value != null && value != undefined && listChoice.length > maxDisplay" class="box-value-single">
                {{tranService.translate("global.text.selectMoreItem")}}
            </div>
            <div *ngIf="listObjectDisplay == null || listObjectDisplay == undefined || listObjectDisplay.length == 0" class="box-value-single">{{floatLabel && isShowBoxSelect ? '' : placeholder}}&nbsp;</div>
        </div>
        <div *ngIf="isMultiChoice == false" class="flex-grow-1" style="max-width: calc(100% - 56px);">
            <div *ngIf="value" class="box-value-single">{{getTextItemDisplay(objectChoice)}}&nbsp;</div>
            <div *ngIf="!value" class="box-value-single">{{floatLabel && isShowBoxSelect ? '' : placeholder}}&nbsp;<span *ngIf="showTextRequired && control.error.required && !isShowBoxSelect" class="text-red-500">*</span></div>
        </div>
        <p-overlayPanel #opViewListChoice>
            <div>
                <p-button icon="pi pi-window-maximize" styleClass="p-button-secondary p-button-text" [label]="tranService.translate('global.button.view')" (click)="openViewListChoice()"></p-button>
            </div>
            <div>
                <p-button *ngIf="!disabled" icon="pi pi-times" styleClass="p-button-secondary p-button-text" [label]="tranService.translate('global.button.clear')" (click)="clearValue()"></p-button>
            </div>
        </p-overlayPanel>
        <span *ngIf="(listChoice.length > 0 || objectChoice) && isMultiChoice == true" tooltipPosition="left" [pTooltip]="tranService.translate('global.text.action')" class="pi pi-ellipsis-v mr-2 ml-2 cursor-pointer" (click)="openMoreAction($event,opViewListChoice)"></span>
        <span *ngIf="!disabled && (listChoice.length > 0 || objectChoice) && isMultiChoice == false" tooltipPosition="left" [pTooltip]="tranService.translate('global.button.delete')" class="pi pi-times mr-2 ml-2 cursor-pointer" (click)="clearValue()"></span>
        <span class="pi pi-chevron-down mr-2 ml-2 cursor-pointer"></span>
    </div>
    <input [disabled]="disabled" class="w-full inputText" [class]="control.invalid && control.dirty ? 'box-invalid' : ''" *ngIf="isAutoComplete == true" (keydown)="controlByKey($event)" (input)="filterOption($event)" type="text" pInputText [placeholder]="floatLabel && isShowBoxSelect ? ' ' : placeholder" [value]="getTextItemDisplay(objectChoice)" (click)="isShowBoxSelect = true"/>
    <div *ngIf="isShowBoxSelect" class="bg-white box-select" [style]="getStyleBoxSelect()">
        <div *ngIf="filter && !isAutoComplete" style="box-sizing: border-box; padding: 12px;" class="w-full flex flex-row justify-content-start align-items-center box-filter">
            <div [class]="isMultiChoice && listChoice.length == 0 ? '' : 'hidden'" class="select-zero mr-2" (click)="toggleSelectAll()"></div>
            <div [class]="isMultiChoice && listChoice.length > 0 && isSelectAll == false ? '' : 'hidden'" class="select-some mr-2" (click)="toggleSelectAll()">
                <span class="pi pi-minus"></span>
            </div>
            <div [class]="isMultiChoice && listChoice.length > 0 && isSelectAll == true ? '' : 'hidden'" class="select-all mr-2" (click)="toggleSelectAll()">
                <span class="pi pi-check"></span>
            </div>
            <input type="text" pInputText class="mr-2 flex-grow-1 inputText" [value]="valueFilter" (input)="filterOption($event)" (keydown)="controlByKey($event)"/>
            <span class="pi pi-times" (click)="clearFilter()"></span>
        </div>
        <div class="box-item" *ngIf="options.length > 0" (scroll)="controlScroll($event)">
            <ul class="list-none p-0 m-0" >
                <li *ngFor="let item of options;let i = index">
                    <div [key]="item[keyReturn]" class="item-select flex flex-row justify-content-start align-items-center" [class]="i == 0 ? 'odd item-select-focus': (i % 2 == 1 ? 'even' : 'odd')">
                        <p-checkbox [target]="id" [inputId]="item[keyReturn]" class="mr-2" *ngIf="isMultiChoice && !isAutoComplete" [value]="item[keyReturn]" name="checkValue" [(ngModel)]="listChoice" (ngModelChange)="changeValueList(item)"></p-checkbox>
                        <span (click)="toggeSelectRow($event, item)" class="item-select-content flex-grow-1">{{getTextItemDisplay(item)}}</span>
                    </div>
                </li> 
            </ul>
        </div>
        <div class="block-box-item" *ngIf="isLoadingItem" [style]="getSizeCoverBoxItem()">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="box-item-empty" *ngIf="options.length == 0">
            <span class="pi pi-inbox" style="font-size: x-large;">&nbsp;</span>{{tranService.translate("global.text.nodata")}}
        </div>
    </div>
</div>

<div class="flex justify-content-center dialog-push-group">
    <p-dialog [header]="tranService.translate('global.button.view')" [(visible)]="isShowDialogViewListChoice" [modal]="true" [style]="{ 'min-width': '500px', width: 'fit-content' }" [draggable]="false" [resizable]="false">
        <div class="w-full" *ngIf="listObjectDisplay?.length > 0">
            <div *ngFor="let item of listObjectDisplay;let i = index" class="box-value-display p-2 mb-2 w-full" [class]="disabled == true ? '' : ''">
                <span *ngIf="!disabled" class="mr-2 pi pi-times" [target]="id" (click)="toggeSelectRow('', item)"></span>
                <div class="value-display white-space-normal" style="min-width: 100%;" [title]="getTextItemDisplay(item)">{{getTextItemDisplay(item)}}</div>
            </div>
        </div>
        <div class="w-full border-box p-4 text-center" *ngIf="listObjectDisplay?.length == 0">
            <span class="pi pi-inbox" style="font-size: x-large;">&nbsp;</span>{{tranService.translate("global.text.nodata")}}
        </div>
    </p-dialog>
</div>