<div class="mt-2 table-qlda relative p-4 bg-white border-round-lg">
    <div class="flex flex-row justify-content-between mb-2" *ngIf="(!options.hasClearSelected && selectItems.length > 0)||labelTable||options.hasShowToggleColumn">
        <div class="text-lg font-bold mb-1">{{labelTable}}</div>
        <div class="flex flex-row align-items-center gap-2" *ngIf="(!options.hasClearSelected && selectItems.length > 0)||options.hasShowToggleColumn">
            <span class="text-base" *ngIf="!options.hasClearSelected && selectItems.length > 0">{{transService.translate("global.text.itemselected")}} {{selectItems.length}}</span>
            <button *ngIf="selectItems.length > 0" pButton  [pTooltip]="clearSelected" tooltipPosition="bottom" class="ml-2" styleClass="" (click)="selectItems=[];modelSelected=[]"><i class=" pi pi-times "></i></button>
            <div style="text-align: right;"  *ngIf="options.hasShowToggleColumn">
                <p-overlayPanel styleClass="p-0" #op>
                    <ng-template pTemplate="content">
                        <div class="max-h-20rem" style="overflow-y: scroll;">
                            <ol style="padding: 0;">
                                <li *ngFor="let item of columns">
                                    <p-checkbox class="mb-1" [(ngModel)]="columnShows" [value]="item.key" inputId="item.key" [label]="item.name" (ngModelChange)="columnShowChanged($event)"></p-checkbox>
                                </li>
                            </ol>
                        </div>
                    </ng-template>
                </p-overlayPanel>
                <button *ngIf="options.hasShowToggleColumn" pButton class="p-button-outlined" (click)="op.toggle($event)"><i class=" pi pi-filter "></i></button>
            </div>
            <!-- <button *ngIf="selectItems.length > 0" pButton  [pTooltip]="clearSelected" tooltipPosition="bottom" class="ml-2" styleClass="" (click)="selectItems=[];modelSelected=[]"><i class=" pi pi-times "></i></button> -->
        </div>
    </div>
    <p-table
        [value]="dataSet?.content"
        [paginator]="dataSet?.total > 0 && options.paginator"
        [rows]="pageSize"
        [first]="rowFirst"
        [showCurrentPageReport]="true"
        [tableStyle]="{ 'min-width': '100%' }"
        [currentPageReportTemplate]="transService.translate('global.text.templateTextPagination')"
        (onPage)="pageChange($event)"
        [rowsPerPageOptions]="rowsPerPageOptions"
        [styleClass]="'p-datatable-sm'"
        [(selection)]="modelSelected"
        [totalRecords]="dataSet?.total"
        [lazy]="true"
        (onSort)="handleSort($event)" [customSort]="true"
        [sortField]="getSortField()"
        [sortOrder]="getSortOrder()"
        #dataTable
        (selectionChange)="handleSelectAllChange($event)"
        [resetPageOnSort]="false"
        [scrollHeight]="scrollHeight"
        [scrollable]="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem" *ngIf="options.hasShowChoose">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngIf="options.hasShowIndex" style="width: 100px;">{{transService.translate('global.text.stt')}}</th>
                <th *ngFor="let item of filterColumnShow(columns)" class="white-space-nowrap"
                    [style]="{'min-width': item.size, 'text-align': item.align, 'width': item.size}"
                    [pSortableColumn]="item.key"
                >{{ item.name }} <p-sortIcon [field]="item.key" *ngIf="item.isSort"></p-sortIcon></th>
                <th pFrozenColumn alignFrozen="right" *ngIf="options.action" style="text-align: center;" class="white-space-nowrap border-left-1">{{transService.translate("global.text.action")}}</th>
            </tr>
            <tr *ngIf="checkEmpty()">
                <td [attr.colspan]="getNumberColumnShow()" [style]="{'min-width': filterColumnShow(columns)[0].size}" class="box-table-nodata">
                    <span class="pi pi-inbox" style="font-size: x-large;">&nbsp;</span>{{transService.translate("global.text.nodata")}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-i="rowIndex">
            <tr>
                <td *ngIf="options.hasShowChoose">
                    <p-tableCheckbox [value]="data"></p-tableCheckbox>
                </td>
                <td *ngIf="options.hasShowIndex" style="width: 100px;">{{i + 1}}</td>
                <td (contextmenu)="handleContextMenu($event)" *ngFor="let item of filterColumnShow(columns)" [style]="{'min-width': item.size,'width':item.size,'text-align':item.align}" [title]="item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key]" class="table-column-qlda"
                >
                    <span *ngIf="!item.funcGetRouting" (click)="handleClickText(item, data)"
                            [style]="item.style"
                            [class]="item.funcGetClassname ? item.funcGetClassname(data[item.key]) : item.className">{{ item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key] }}</span>
                    <a [routerLink]="item.funcGetRouting(data)"
                            routerLinkActive="router-link-active"
                            *ngIf="item.funcGetRouting"
                            [style]="item.style"
                            [class]="item.funcGetClassname ? item.funcGetClassname(data[item.key]) : item.className">{{ item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key] }}</a>
                </td>
                <td pFrozenColumn alignFrozen="right" *ngIf="options.action" class="border-left-1">
                    <div class="flex flex-row grap-2 justify-content-center align-items-center">
                        <span *ngFor="let op of filterAction(options.action, data, data[fieldId])"
                            class="inline-block mr-1 cursor-pointer"
                            [class]="op.icon"
                            (click)="op.func(data[fieldId], data)"
                            [pTooltip]="op.tooltip"
                            tooltipPosition="left"
                        ></span>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
            <div class="flex flex-row justify-content-start align-items-center grap-2" *ngIf="!options.hasClearSelected && selectItems.length > 0">
<!--                <span>{{selectItems.length}} {{transService.translate("global.text.itemselected")}}</span>-->
<!--                <p-button icon="pi pi-times" styleClass="p-button-secondary" class="ml-2" (click)="selectItems=[];modelSelected=[]"></p-button>-->
            </div>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <div class="flex flex-row justify-content-start align-items-center grap-2" *ngIf="options.hasShowJumpPage !== false">
                <div class="mr-2">{{transService.translate('global.text.page')}}</div>
                <p-inputNumber (keyup.enter)="jumpPage(pageC)" [(ngModel)]="pageCurrent" mode="decimal" [min]="1" [max]="getMaxPage()"> </p-inputNumber>
            </div>
        </ng-template>
    </p-table>
</div>
