import global from "./global";

import account from "./account";
import error from "./error";
import login from "./login";
import project from "./project";

export const vi = {
    global,
    account,
    error,
    login,
    project
}
