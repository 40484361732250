import { Injectable } from "@angular/core";
import { Observable, Subscription, of } from "rxjs";

@Injectable({ providedIn: 'root'})
export class ObservableService{
    storage: any;
    constructor() {
        this.storage = {};
    }

    public init(key: string, data?:any){
        let me = this;
        if(key in this.storage){
            if(data && this.storage[key].observer){
                this.storage[key].observer.next(data);
            }
        }else{
            this.storage[key] = {};
            let observable = new Observable((observer)=>{
                me.storage[key].observer = observer;
                if(data && this.storage[key].observer){
                    observer.next(data);
                }
            });
            this.storage[key].observable = observable;
        }
    }

    public next(key: string, data: any){
        this.init(key, data);
    }

    public subscribe(key: string, action:{next?:Function, error?:Function, complete?:Function}):Subscription{
        this.init(key);
        return this.storage[key].observable.subscribe(action);
    }
}
