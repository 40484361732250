import { Injector, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ComponentBase } from 'src/app/component.base';
import { CONSTANTS } from 'src/app/service/comon/constants';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent extends ComponentBase implements OnInit {

    model: MenuItem[] = [];

    constructor(public layoutService: LayoutService, private injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.model = [
            { 
                label: this.tranService.translate('global.menu.managerProject'),
                items: [
                    { label: this.tranService.translate('global.menu.listProject'), icon: 'pi pi-fw pi-id-card', routerLink: ['/projects'], visible: this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.ADMIN},
                    { label: this.tranService.translate('global.menu.listRegisterProject'), icon: 'pi pi-fw pi-check-square', routerLink: ['/history'], visible: this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.STUDENT},
                ]
            }
        ];
        if(this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.STUDENT){
            this.model.splice(0, 0, { 
                label: this.tranService.translate('global.menu.home'), 
                visable: this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.STUDENT,
                items: [
                    { label: this.tranService.translate('global.menu.home'), icon: 'pi pi-fw pi-id-card', routerLink: ['/home']},
                ]
            })
        }
        if(this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.ADMIN){
            this.model.push({
                label: this.tranService.translate('global.menu.managerStudent'),
                items: [
                    { label: this.tranService.translate('global.menu.listStudent'), icon: 'pi pi-fw pi-id-card', routerLink: ['/students'] },
                ],
                visible: this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.ADMIN
            })
        }
    }
}
