import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./template/layout/app.layout.component";
import { AppAccountProfileComponent } from './template/student/profile/app.student.profile.component';
import { AppChangePasswordComponent } from './template/student/change-password/app.change.password';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                children: [
                    {path: "projects", loadChildren: () => import('./template/project/app.project.module').then(m => m.AppProjectModule)},
                    {path: "students", loadChildren: () => import('./template/student/app.student.module').then(m => m.AppStudentModule)},
                    { path: 'home', loadChildren: () => import('./template/home/app.home.module').then(m => m.AppHomeModule) },
                    { path: 'history', loadChildren: () => import('./template/history/app.history.module').then(m => m.AppHistoryModule) },
                    {path: "profile", component: AppAccountProfileComponent},
                    {path: "change-password", component: AppChangePasswordComponent}
                ]
            },
            { path: 'error', loadChildren: () => import('./template/pages/error/error.module').then(m => m.ErrorModule) },
            { path: 'access', loadChildren: () => import('./template/pages/access/access.module').then(m => m.AccessModule) },
            { path: 'login', loadChildren: () => import('./template/pages/login/login.module').then(m => m.LoginModule) },
            { path: 'reset-password', loadChildren: () => import('./template/pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
            { path: 'notfound', loadChildren: () => import('./template/pages/notfound/notfound.module').then(m => m.NotfoundModule) },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
