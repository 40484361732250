<div class="relative">
    <div (click)="op.toggle($event)" class="cursor-pointer">
        <img *ngIf="lang == 'vi'" src="assets/images/flag/flag_placeholder.png" width="30" class="flag flag-vn">
        <img *ngIf="lang == 'en'" src="assets/images/flag/flag_placeholder.png" width="30" class="flag flag-gb">
    </div>
    <p-overlayPanel #op [style]="{'width':'150px'}">
        <ng-template pTemplate="content">
            <div class="flex flex-row justify-content-start align-content-center mb-2 cursor-pointer"
                (click)="changeLanguage('vi')"
            >
                <img src="assets/images/flag/flag_placeholder.png" width="30" class="flag flag-vn mr-2">
                <div class="white-space-nowrap">{{transService.translate("global.lang.vi")}}</div>
            </div>
            <div class="flex flex-row justify-content-start align-content-center cursor-pointer"
                (click)="changeLanguage('en')"
            >
                <img src="assets/images/flag/flag_placeholder.png" width="30" class="flag flag-gb mr-2">
                <div class="white-space-nowrap">{{transService.translate("global.lang.en")}}</div>
            </div>
        </ng-template>
    </p-overlayPanel>
    <!-- <div #actualTarget id="chooseLanguage"></div> -->
</div>