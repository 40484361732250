import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { ComponentBase } from 'src/app/component.base';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent extends ComponentBase implements OnInit{

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    username: string;
    constructor(public layoutService: LayoutService, private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.username = (this.sessionService.userInfo || {}).name;
        this.items = [
            {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
            },
            {
                label: 'Delete',
                icon: 'pi pi-fw pi-trash'
            }
        ]
    }

    logout(){
        let me = this;
        me.messageCommonService.onload();
        this.sessionService.logout((response)=>{
            localStorage.clear();
            me.messageCommonService.offload();
            window.location.href = "/login";
        }, null, ()=>{
            me.messageCommonService.offload();
        })
    }
}
