<style>
    .toggle-password{
        display: inline-block;
        position: absolute;
        right: 12px;
        top: 46%;
        transform: translateY(-50%);
        cursor: pointer;
    }
</style>
<div class="qlda flex flex-row justify-content-between align-items-center bg-white p-2 border-round">
    <div class="">
        <div class="text-xl font-bold mb-1">{{this.tranService.translate("global.text.accountInfo")}}</div>
        <p-breadcrumb class="max-w-full col-7" [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <div class="col-5 flex flex-row justify-content-end align-items-center">
        
    </div>
</div>

<p-card styleClass="mt-3">
    <form [formGroup]="formChangePass" (ngSubmit)="submitChangePass(changePassInfo.oldPassword)">
        <!-- old passs -->
        <div class="grid mt-5">
            <div class="col-12">
                <div class="col-3 p-0 pb-2 relative">
                    <span class="p-float-label">
                        <input class="w-full" [type]="isShowOldPass ? 'text': 'password'"
                            pInputText id="oldPassword"
                            [(ngModel)]="changePassInfo.oldPassword"
                            formControlName="oldPassword"
                            [required]="true"
                            [maxLength]="50"
                            [placeholder]="tranService.translate('account.label.oldPass')"
                        />
                        <label htmlFor="oldPassword">{{tranService.translate("account.label.oldPass")}}<span
                            class="text-red-500">*</span></label>
                    </span>
                    <label *ngIf="isShowOldPass == false" class="pi pi-eye toggle-password" (click)="isShowOldPass = true"></label>
                    <label *ngIf="isShowOldPass == true" class="pi pi-eye-slash toggle-password" (click)="isShowOldPass = false"></label>
                </div>
                <!-- errr old passs -->
                <div class="text-error-field">
                    <div class="pt-3">
                        <small class="text-red-500 block"
                               *ngIf="formChangePass.controls.oldPassword.dirty && formChangePass.controls.oldPassword.errors?.required">{{tranService.translate(
                            "global.message.required")}}</small>
                        <small class="text-red-500 block" *ngIf="formChangePass.controls.oldPassword.errors?.maxLength">{{tranService.translate(
                            "global.message.maxLength", {len: 50})}}</small>
<!--                        <small class="text-red-500 block" *ngIf="formChangePass.controls.oldPassword.errors?.pattern">{{tranService.translate(-->
<!--                            "global.message.invalidPasswordFomat")}}</small>-->
                        <small class="text-red-500 block" *ngIf="!isValidOldPass">{{tranService.translate("global.message.wrongCurrentPassword")}}</small>
                    </div>
                </div>
            </div>
            <!-- new passs -->
            <div class="col-12">
                <div class="col-3 p-0 pb-2 relative">
                    <span class="p-float-label">
                        <input class="w-full"
                               pInputText id="newPassword" [type]="isShowNewPass ? 'text': 'password'"
                               [(ngModel)]="changePassInfo.newPassword"
                               formControlName="newPassword"
                               [required]="true"
                               [maxLength]="255"
                               pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$"
                               [placeholder]="tranService.translate('account.label.newPass')"
                        />
                         <label htmlFor="newPassword">{{tranService.translate("account.label.newPass")}}<span
                             class="text-red-500">*</span></label>
                    </span>
                    <label *ngIf="isShowNewPass == false" class="pi pi-eye toggle-password" (click)="isShowNewPass = true"></label>
                    <label *ngIf="isShowNewPass == true" class="pi pi-eye-slash toggle-password" (click)="isShowNewPass = false"></label>
                </div>
                <!-- errr new passs -->
                <div class="w-full text-error-field">
                    <div class="pt-3">
                        <small class="text-red-500"
                               *ngIf="formChangePass.controls.newPassword.dirty && formChangePass.controls.newPassword.errors?.required">{{tranService.translate(
                            "global.message.required")}}</small>
                        <small class="text-red-500" *ngIf="formChangePass.controls.newPassword.errors?.maxLength">{{tranService.translate(
                            "global.message.maxLength", {len: 255})}}</small>
                        <small class="text-red-500" *ngIf="formChangePass.controls.newPassword.errors?.pattern">{{tranService.translate(
                            "global.message.invalidPasswordFomat")}}</small>
                    </div>
                </div>
            </div>

            <!-- confirm passs -->
            <div class="col-12">
                <div class="col-3 p-0 pb-2 relative">
                    <span class="p-float-label">
                        <input class="w-full"
                               pInputText id="confirmPassword" [type]="isShowRepeatPass ? 'text': 'password'"
                               [(ngModel)]="changePassInfo.confirmPassword"
                               formControlName="confirmPassword"
                               [required]="true"
                               [maxLength]="255"
                               pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$"
                               [placeholder]="tranService.translate('account.label.confirmPass')"
                        />
                       <label htmlFor="confirmPassword">{{tranService.translate("account.label.confirmPass")}}<span
                           class="text-red-500">*</span></label>
                   </span>
                   <label *ngIf="isShowRepeatPass == false" class="pi pi-eye toggle-password" (click)="isShowRepeatPass = true"></label>
                    <label *ngIf="isShowRepeatPass == true" class="pi pi-eye-slash toggle-password" (click)="isShowRepeatPass = false"></label>
                </div>

                <!-- error confirm passs -->
                <div class="w-full text-error-field">
                    <div class="pt-3">
                        <small class="text-red-500 block"
                               *ngIf="formChangePass.controls.confirmPassword.dirty && formChangePass.controls.confirmPassword.errors?.required">{{tranService.translate(
                            "global.message.required")}}</small>
                        <small class="text-red-500 block"
                               *ngIf="formChangePass.controls.confirmPassword.errors?.maxLength">{{tranService.translate(
                            "global.message.maxLength", {len: 255})}}</small>
                        <small class="text-red-500 block"
                               *ngIf="formChangePass.controls.confirmPassword.errors?.pattern">{{tranService.translate(
                            "global.message.invalidPasswordFomat")}}</small>
                        <small class="text-red-500 block"
                               *ngIf="changePassInfo.confirmPassword != '' &&  changePassInfo.confirmPassword != changePassInfo.newPassword">{{tranService.translate(
                            "global.message.passwordNotMatch")}}</small>
                    </div>
                </div>

            </div>

        </div>
        <div class="flex">
            <div class="col-3"></div>
            <div class="col-9 flex">
                <p-button [label]="tranService.translate('global.button.cancel')" styleClass="p-button-secondary p-button-outlined mr-2"  [routerLink]="'/profile'"></p-button>
                <p-button type="submit" [label]="tranService.translate('global.button.changePass')" styleClass="p-button-info"
                           [disabled]="formChangePass.status == 'INVALID'"></p-button>
            </div>
        </div>
    </form>
</p-card>
