import { Inject, Injectable } from "@angular/core";
import { HttpService } from "../comon/http.service";

@Injectable({ providedIn: 'root'})
export class DomainService{
    private prefixApi: string;
    constructor(@Inject(HttpService) private httpService:HttpService) {
        this.prefixApi = "/domains";
    }

    getAll(callback){
        this.httpService.get(`${this.prefixApi}`, {}, {}, callback);
    }
}