import { AfterContentChecked, Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { ComponentBase } from "src/app/component.base";
import { ActionInfo } from "./table.component";
import { CONSTANTS } from "src/app/service/comon/constants";
import { FormBuilder } from "@angular/forms";
import { co } from "@fullcalendar/core/internal-common";

export interface ColumnInputInfo{
    name: string,//tên hiển thị
    key: string,// key lấy giá trị
    size: string,// kích thước cột
    align: "left"|"right"|"center",//căn lề
    type: number,// loại dữ liệu
    validate?: {
        required?: boolean | null,//có bắt buộc hay không
        pattern?: string | RegExp | null,//regex
        messageErrorPattern?: string | null,//message khi sai regex
        maxLength?: number | null,//độ dài tối đa nếu là kiểu chuỗi
        minLength?: number | null,//độ dài tối thiểu nếu là kiểu chuỗi
        max?: number | null,//giá trị tối đa nếu là kiểu số
        min?: number | null,// giá trị tối thiểu nếu là kiểu số
        maxDate?: Date | null,// giá trị tối đa nếu là kiểu ngày
        minDate?: Date | null,// giá trị tối thiểu nếu là kiểu ngày
        maxTimestamp?: Date | null,// giá trị tối đa nếu là kiểu timestamp
        minTimestamp?: Date | null,// giá trị tối thiểu nếu là kiểu timestamp
        exists?: boolean | null,// có check tồn tại trong bảng r hay không
    },
    allowUpdate?: boolean,// có cho phép update hay không
    valueList?: Array<any>,// danh sách giá trị nếu là dropdown
    optionLabel?: string,// giá trị hiển thị
    optionValue?: string,// trường lấy giá trị
    style?: {[className: string]: any},// css
    className?: string | Array<string>,// class css
    funcGetClassname?: (value: any) => string | Array<string>,// hàm lấy class
    funcConvertText?: (value: any) => string,// hàm lấy giá trị hiển thị
    funcGetRouting?:(item: any) => Array<any>,// hàm lấy giá trị link
    funcClick?: (id:any, item:any) => void//hàm xử lý khi click
}
export interface OptionTableInput{
    mode: number;//kiểu hiển thị (create,update,detail)
    action?: Array<ActionInfo>;//danh sách các nút trong cột thao tác
}
export class TableInputControl{
    public isUpdating: boolean = false;//có đang update hay không
    public reset: Function;//hàm reset lại giá trị bảng
}
@Component({
    selector: "table-input-qlda",
    templateUrl: "./table.input.component.html"
})
export class TableInputComponent extends ComponentBase implements OnInit, AfterContentChecked{
    constructor(injector: Injector,
                private formBuider: FormBuilder) {
        super(injector);
    }

    @Input() value!: Array<any>;//giá trị hiển thị trong bảng
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Input() scrollHeight?: string = "flex";//chiều cao tối đa
    @Input() columns!: ColumnInputInfo[];//danh sách cột
    @Input() options!: OptionTableInput;//option của bảng
    @Input() fieldId?: string = "id";// trường được chọn làm id
    @Input() control!: TableInputControl;// đối tượng điều khiển bảng
    @Input() showMove?: boolean = false;// có hiển thị nút di chuyển hàng hay không

    objectEdit: any;
    objectMode = CONSTANTS.MODE_VIEW;
    objectType = CONSTANTS.PARAMETER_TYPE;
    formEdit: any = null;

    listObject: Array<any>;
    idFake: number = -2;
    isUpdating: boolean = false;
    mapErrorExists: any = {};
    
    ngOnInit(): void {
        this.fillValueDefaults();
        this.fillDataControl();
        this.control.reset = this.reset.bind(this);
    }

    ngAfterContentChecked(): void {
        
    }

    fillDataControl(){
        if(this.value == undefined || this.value == null){
            this.listObject = [];
        }else{
            this.listObject = [...this.value];
        }
        this.idFake = -2;
        this.listObject.forEach(el => {
            if(el[this.fieldId] == null || el[this.fieldId] == undefined || el[this.fieldId] < 0){
                el[this.fieldId] = this.idFake --;
            }
        })
        this.listObject.forEach(el => el.mode = CONSTANTS.MODE_VIEW.DETAIL);
        if(this.listObject.filter(el => el.id == -1).length == 0){
            this.listObject.push({[this.fieldId]: -1, mode: CONSTANTS.MODE_VIEW.DETAIL});
        }
        if(this.listObject.filter(el => el.id == 0).length == 0){
            this.listObject.push({[this.fieldId]: 0, mode: CONSTANTS.MODE_VIEW.DETAIL});
        }
        this.mapErrorExists = {};
        this.formEdit = undefined;
        this.objectEdit = null;
        this.control.isUpdating = false;
    }

    fillValueDefaults(){
        this.columns.forEach(el => {
            if(el.allowUpdate == null || el.allowUpdate == undefined){
                el.allowUpdate = true;
            }
            if(el.validate == undefined){
                el.validate = {
                    required: null,
                    pattern: null,
                    messageErrorPattern: this.tranService.translate("global.message.invalidValue"),
                    minLength: null,
                    maxLength: null,
                    min: null,
                    max: null,
                    maxDate: null,
                    minDate: null,
                    maxTimestamp: null,
                    minTimestamp: null,
                    exists: null
                }
            }else if(el.validate != null){
                if(el.validate.pattern){
                    if(!el.validate.messageErrorPattern){
                        el.validate.messageErrorPattern = this.tranService.translate("global.message.invalidValue");
                    }
                }
            }
        })
    }

    createNew(){
        if(this.checkInvalidValidForm()) return;
        this.resetMapErrorExists();
        this.control.isUpdating = true;
        this.listObject.forEach(el => {
            el.mode = CONSTANTS.MODE_VIEW.DETAIL;
            if(el.id == -1){
                el.mode = CONSTANTS.MODE_VIEW.CREATE;
                this.objectEdit = {...el};
            }
        });
        this.columns.forEach(el => {
            this.objectEdit[el.key] = null;
        });
        this.formEdit = this.formBuider.group(this.objectEdit);
    }

    checkEmpty(){
        return (this.listObject || []).length == 0;
    }

    getColspan(){
        return (this.columns || []).length + 1;
    }

    filterAction(actions: Array<ActionInfo>, item: any, id: string){
        if(this.options && this.options.action){
            return actions.filter(el => {
                return el.funcAppear == undefined || el.funcAppear(id, item);
            })
        }
        return [];
    }

    handleClickText(column: ColumnInputInfo, data: any){
        if(column.funcClick){
            column.funcClick(data[this.fieldId], data);
        }
    }

    editRow(id, item){
        if(this.options.mode == CONSTANTS.MODE_VIEW.DETAIL) return;
        if(this.checkInvalidValidForm()) return;
        this.resetMapErrorExists();
        this.control.isUpdating = true;
        this.objectEdit = {...item};
        this.columns.forEach(el => {
            if(el.type == CONSTANTS.PARAMETER_TYPE.TIMESTAMP){
                this.objectEdit[el.key] = new Date(item[el.key]);
            }
        })
        this.formEdit = this.formBuider.group(this.objectEdit);
        this.listObject.forEach(el => {
            el.mode = CONSTANTS.MODE_VIEW.DETAIL;
            if(el.id == id){
                el.mode = CONSTANTS.MODE_VIEW.UPDATE;
            }
        });
    }

    saveObject(id, item){
        this.control.isUpdating = false;
        this.formEdit = undefined;
        this.columns.forEach(el => {
            if(el.type == CONSTANTS.PARAMETER_TYPE.TIMESTAMP){
                this.objectEdit[el.key] = this.objectEdit[el.key].getTime();
            }
        })
        if(id == -1){
            this.objectEdit[this.fieldId] = this.idFake --;
            this.objectEdit.mode = CONSTANTS.MODE_VIEW.DETAIL;
            this.listObject = [...this.listObject.slice(0, this.listObject.length -2), this.objectEdit, ...this.listObject.slice(this.listObject.length - 2, this.listObject.length)];
            this.objectEdit = null;
        }else{
            for(let i = 0; i < this.listObject.length;i++){
                if(this.listObject[i][this.fieldId] == id){
                    this.objectEdit.mode = CONSTANTS.MODE_VIEW.DETAIL;
                    this.listObject[i] = this.objectEdit;
                    this.objectEdit = null;
                    break;
                }
            }
        }
        let me = this;
        this.emitValue();
    }

    emitValue(){
        let me = this;
        let valueEmit = this.listObject.slice(0, this.listObject.length -2).map(el => {
            let data = {...el};
            let index = el[me.fieldId];
            data[me.fieldId] = index > 0 ? index : null;
            delete data.mode;
            return data;
        })
        this.valueChange.emit(valueEmit);
        this.onChange.emit(valueEmit);
    }

    resetMapErrorExists(){
        this.mapErrorExists = {};
        this.columns.forEach(el => {
            this.mapErrorExists[el.key] = false;
        })
    }

    resetForm(id, item){
        this.resetMapErrorExists();
        if(id == -1){
            this.formEdit.reset();
        }else{
            this.objectEdit = {...item};
            this.columns.forEach(el => {
                if(el.type == CONSTANTS.PARAMETER_TYPE.TIMESTAMP){
                    this.objectEdit[el.key] = new Date(item[el.key]);
                }
            })
            this.formEdit = this.formBuider.group(this.objectEdit);
        }
    }

    removeItem(id, item){
        if(id == -1){
            this.control.isUpdating = false;
            for(let i = 0; i < this.listObject.length;i++){
                if(this.listObject[i][this.fieldId] == -1){
                    this.listObject[i] = {[this.fieldId]: -1, mode: CONSTANTS.MODE_VIEW.DETAIL};
                    break;
                }
            }
            this.formEdit = undefined;
            this.objectEdit = null;
        }else{
            for(let i = 0; i < this.listObject.length;i++){
                if(this.listObject[i][this.fieldId] == id){
                    if(this.listObject[i].mode != CONSTANTS.MODE_VIEW.DETAIL){
                        this.control.isUpdating = false;
                        this.formEdit = undefined;
                        this.objectEdit = null;
                    }
                    this.listObject.splice(i, 1);
                }
            }
            this.emitValue();
        }
    }

    pushUp(index, id, item){
        this.listObject[index] = this.listObject[index - 1];
        this.listObject[index - 1]= item;
        this.emitValue();
    }

    pushDown(index, id, item){
        this.listObject[index] = this.listObject[index + 1];
        this.listObject[index + 1]= item;
        this.emitValue();
    }

    cancel(id, item){
        this.control.isUpdating = false;
        if(id == -1){
            for(let i = 0; i < this.listObject.length;i++){
                if(this.listObject[i][this.fieldId] == -1){
                    this.listObject[i] = {[this.fieldId]: -1, mode: CONSTANTS.MODE_VIEW.DETAIL};
                    break;
                }
            }
        }else{
            for(let i = 0; i < this.listObject.length;i++){
                if(this.listObject[i][this.fieldId] == id){
                    this.listObject[i].mode = CONSTANTS.MODE_VIEW.DETAIL;
                }
            }
        }
    }

    checkExists(column: ColumnInputInfo){
        let me = this;
        if(column.validate != undefined && column.validate != null){
            if(column.validate.exists == true){
                let values = this.listObject.filter(el => el[me.fieldId] != 0 && el[me.fieldId] != -1 && el[me.fieldId] != me.objectEdit[me.fieldId])
                                            .map(el => el[column.key]);
                this.mapErrorExists[column.key] = values.includes(this.objectEdit[column.key]);
            }
        }
    }

    checkInvalidValidForm(){
        if(this.formEdit){
            let flagExists = false;
            let me = this;
            Object.keys(this.mapErrorExists).forEach(el => {
                if(me.mapErrorExists[el] == true){
                    flagExists = true;
                }
            })
            return this.formEdit.invalid || flagExists
        }
        return false;
    }

    reset(){
        let me = this;
        setTimeout(function(){
            me.fillValueDefaults();
            me.fillDataControl();
        });
    }
}