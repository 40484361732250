import { Component, EventEmitter, HostListener, Injector, Input, OnInit, ElementRef, ChangeDetectorRef, AfterContentChecked, Output } from "@angular/core";
import { ComponentBase } from "src/app/component.base";
import { AccountService } from "src/app/service/account/AccountService";
import { CONSTANTS } from "src/app/service/comon/constants";
import { ProjectService } from "src/app/service/project/project.service";

export const ARRAY_SERVICE = [
    {
        name: "account",
        display: "global.menu.student",
        service: AccountService,
        keyId: "id"
    },
    {
        name: "project",
        display: "global.menu.project",
        service: ProjectService,
        keyId: "id"
    }
]

export class ComboLazyControl {
    dirty: boolean = false;
    error: {
        required: boolean,
        max: boolean,
        maxlength: boolean,
        minlength: boolean,
        pattern: boolean,
    } = {required: false, max: false, minlength: false, maxlength: false, pattern: false};
    invalid: boolean = false;
}

@Component({
    selector: "custom-select",
    templateUrl: "./combobox.lazyload.html",
    styleUrls: ["./combobox.lazyload.css"]
})
export class CustomCombobox extends ComponentBase implements OnInit, AfterContentChecked{

    @HostListener('document:click', ['$event'])
    clickout(event: PointerEvent) {
        let myNode: Element = this.eRef.nativeElement;
        let boxSelectNode: Element = myNode.querySelector("div.box-select");
        let top = event.clientY
        let left = event.clientX;
        if(myNode){
            let topCheck = myNode.getBoundingClientRect()["top"];
            let bottomCheck = myNode.getBoundingClientRect()["bottom"];
            let leftCheck = myNode.getBoundingClientRect()["left"];
            let rightCheck = myNode.getBoundingClientRect()["right"];
            if(top >= topCheck && top <= bottomCheck && left >= leftCheck && left <= rightCheck) return;
        }
        if(boxSelectNode){
            let topCheck = boxSelectNode.getBoundingClientRect()["top"];
            let bottomCheck = boxSelectNode.getBoundingClientRect()["bottom"];
            let leftCheck = boxSelectNode.getBoundingClientRect()["left"];
            let rightCheck = boxSelectNode.getBoundingClientRect()["right"];
            if(top >= topCheck && top <= bottomCheck && left >= leftCheck && left <= rightCheck) return;
        }
        // if(event.target.target == this.id) return;
        if(!this.eRef.nativeElement.contains(event.target)) {
            this.isShowBoxSelect = false;
        }
    }

    constructor(private injector: Injector,private eRef: ElementRef,private chRef: ChangeDetectorRef) {
        super(injector);
    }
    
    @Input() control?: ComboLazyControl = new ComboLazyControl();// object điều khiển, kiểm tra invalid, trạng thái thay đổi
    @Input() value!: any;// giá trị của combobox
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() onchange: EventEmitter<any> = new EventEmitter();
    @Input() styleClass?: string | string[] = null;//css class
    @Input() style?: any = null;//style css
    @Input() disabled?: boolean = false;// có disable hay không
    @Input() required?: boolean = false// có bắt buộc hay không
    @Input() pattern?: RegExp = null;// regex giá trị với trường họp là autocomplete
    @Input() minlength?: number = 0;// độ dài chuỗi tối thiểu với trường hợp là autocomplete
    @Input() maxlength?: number = Number.MAX_SAFE_INTEGER;// độ dài chuỗi tối đa với trường hợp là autocomplete
    @Input() maxSelect?: number = Number.MAX_SAFE_INTEGER;// số lượng tối đa được chọn với trường hợp là multiselect
    @Input() isAutoComplete?: boolean = false;// có phải là autocomplete hay ko
    @Input() isMultiChoice?: boolean = true;// có được lựa chọn nhiều hay không
    @Input() options?: Array<any> = [];// danh sách lựa chọn
    @Input() objectKey?: string = "customer";// key service dùng để load options
    @Input() paramKey?: string = "customerName";// key param dùng để load options theo filter
    @Input() paramDefault?: any = {};// bộ param mặc định
    @Input() keyReturn?: string = "customerCode";// key dùng để lấy giá trị trả về
    @Input() displayPattern?: string = "${customerName} - ${customerCode}";// cấu hình hiển thị trong danh sách lựa chọn
    @Input() lazyLoad?: boolean = true;// có sử dụng lazyload hay ko
    @Input() sizeLoad?: number = 10;// số lượng bản ghi mỗi lần load thêm
    @Input() typeValue?: "primitive"| "object" = "primitive";// loại dữ liệu trả về 'giá trị nguyên thủy' | 'đối tượng'
    @Input() filter?: boolean = true;// có cho filter hay không
    @Input() isFilterLocal?: boolean = false;// có filter ở local hay không
    @Input() placeholder?: string = this.tranService.translate("global.text.selectOption");// placeholder
    @Input() floatLabel?: boolean = false;// có cho label float hay không
    @Input() stylePositionBoxSelect?: any = null;// style cho hộp lựa chọn + tìm kiếm
    @Input() showTextRequired?: false;// có hiển thị dấu required hay không
    @Input() loadData?: Function; // custom hàm load option (data, callback) hàm callback(response: {content: Array<any>, totalPages: number})

    id: number = Math.floor(Math.random()*100000);
    sort: string = `${this.paramKey},asc`;
    page:number = 0;
    size:number = 25;
    maxSizeCache: number = this.size * 4;
    totalPage: number = 0;
    typeAppend: "head" | "foot" | "clear" = "clear";

    isSelectAll: boolean = false;
    listChoice: Array<any> = [];
    objectChoice: any = null;
    listObjectDisplay: Array<any> = [];
    valueFilter: string = "";
    isShowBoxSelect: boolean = false;
    isLoadingItem: boolean = false;
    heightRow = 38;
    maxHeightBoxItem = this.heightRow * 5;
    maxDisplay: number = 4;
    oldValue: any;
    optionOrigins: Array<any> = [];
    optionOld: any = [];
    isCallApi: boolean = false;
    firstLoadValue: boolean = true;
    defaultParamOld: any = {};
    isShowDialogViewListChoice = false;

    ngOnInit(): void {
        this.sort = `${this.paramKey},asc`;
        let me = this;
        me.load();
    }

    ngAfterContentChecked(): void {
        let myNode: Element = this.eRef.nativeElement;
        if(this.disabled){
            if(myNode.querySelector(".box-dropdown")){
                myNode.querySelector(".box-dropdown").classList.add("box-disable");
            }
        }else{
            if(myNode.querySelector(".box-dropdown")){
                myNode.querySelector(".box-dropdown").classList.remove("box-disable");
            }
        }
        this.updateValidate();
        if(this.isAutoComplete == false && this.isMultiChoice == true){
            if(JSON.stringify(this.oldValue || []) != JSON.stringify(this.value || [])){
                this.oldValue = [...(this.value || [])];
                this.loadValue();
            }
        }else{
            if(this.typeValue == "primitive"){
                if(this.oldValue != this.value){
                    this.oldValue = this.value;
                    this.loadValue();
                }
            }else{
                if(JSON.stringify(this.oldValue || {}) != JSON.stringify(this.value || {})){
                    this.oldValue = {...(this.value || {})};
                    this.loadValue();
                }
            }
        }
        
        if(JSON.stringify(this.optionOld) != JSON.stringify((this.options || []))){
            this.optionOld = [...(this.options || [])];
            this.isSelectAll = this.checkSelectAll();
            this.loadValue(false);
        }
        if(JSON.stringify(this.defaultParamOld) != JSON.stringify(this.paramDefault)){
            // console.log("run by default param change", this.defaultParamOld, this.paramDefault)
            this.defaultParamOld = {...this.paramDefault};
            this.page = 0;
            this.typeAppend = "clear";
            this.loadOption();
        }
        
    }

    updateValidate(){
        let me = this;
        this.control.error.required = false;
        this.control.error.max = false;
        if(this.required == true){
            if(this.value == undefined || this.value == null){
                this.control.error.required = true;
            }else{
                if(this.isAutoComplete == false && this.isMultiChoice){
                    if(this.value.length == 0){
                        this.control.error.required = true;
                    }
                }else{
                    if((this.value + "").length == 0){
                        this.control.error.required = true;
                    }
                }
            }
        }
        if(this.isAutoComplete == false && this.isMultiChoice){
            if((this.value || []).length > this.maxSelect){
                this.control.error.max = true;
            }
        }
        this.control.invalid = false;
        Object.keys(this.control.error).forEach(keyError => {
            if(me.control.error[keyError] == true){
                me.control.invalid = true;
            }
        })
        this.control.error.maxlength = false;
        this.control.error.minlength = false;
        this.control.error.pattern = false;
        if(this.isAutoComplete){
            if(this.value != null && this.value != undefined){
                if(this.pattern && !this.pattern.test(this.value)){
                    this.control.error.pattern = true;
                }else{
                    if(this.value.length < this.minlength){
                        this.control.error.minlength = true;
                    }
                    if(this.value.length > this.maxlength){
                        this.control.error.maxlength = true;
                    }
                }
            }
        }
    }

    openSugget(){
        if(this.disabled) return;
        let me = this;
        if(this.isShowBoxSelect == false){
            this.isShowBoxSelect = true;
            setTimeout(function(){
                let input = me.eRef.nativeElement.querySelector("input.inputText");
                if(input){
                    input.focus();
                }else{
                    me.eRef.nativeElement.querySelector("input.inputTextHidden").focus();
                }
            })
        }
    }

    getService():any{
        for(let i = 0;i<ARRAY_SERVICE.length;i++){
            if(ARRAY_SERVICE[i].name == this.objectKey){
                return ARRAY_SERVICE[i];
            }
        }
        return null;
    }

    filterOption(event){
        let me = this;
        this.valueFilter = event.target.value;

        if((this.valueFilter.trim() != this.getTextItemDisplay(this.objectChoice) || this.valueFilter.trim() == "")){
            this.page = 0;
            this.typeAppend = "clear";
            if(this.isCallApi && !this.isFilterLocal){
                this.debounceService.set("filter-combobox-lazy", this.loadOption.bind(this));
            }else{
                this.debounceService.set("filter-combobox-lazy", this.filterLocal.bind(this));
            }
        }
        if(this.isAutoComplete){
            this.objectChoice = null;
            this.value = this.valueFilter;
            this.valueChange.emit(this.value);
            this.onchange.emit(this.value);
        }
    }

    filterLocal(){
        let me = this;
        let valueCheck = this.valueFilter.toUpperCase();
        if(valueCheck == this.utilService.convertTextViToEnUpperCase(valueCheck)){
            this.options = this.optionOrigins.filter(el => me.utilService.convertTextViToEnUpperCase((el[me.paramKey] || "")).indexOf(valueCheck)>=0);
        }else{
            this.options = this.optionOrigins.filter(el => (el[me.paramKey] || "").toUpperCase().indexOf(valueCheck)>=0);
        }
    }

    loadOption(){
        let me = this;
        let data = {
            [this.paramKey]: this.valueFilter || '',
            page: this.page,
            size: this.size,
            sort: this.sort,
            ...this.paramDefault
        }
        let service = this.getService().service;
        if(this.loadData != null && this.loadData != undefined){
            me.isLoadingItem = true;
            let timeout = setTimeout(function(){
                me.isLoadingItem = false;
            },CONSTANTS.MAX_TIME_HTTP_WAIT);
            this.loadData(data,(response)=>{
                me.executeResponseLoadOption(response, timeout)
            })
        }else if(service != null){
            if(this.objectKey == "province"){
                this.injector.get(service).getListProvince((response)=>{
                    me.options = response.sort((a,b)=> a.name.toUpperCase().localeCompare(b.name.toUpperCase()) > 0 ? 1 : -1);
                    me.optionOrigins = [...this.options];
                    me.isCallApi = false;
                })
            }else{
                me.isLoadingItem = true;
                let timeout = setTimeout(function(){
                    me.isLoadingItem = false;
                },CONSTANTS.MAX_TIME_HTTP_WAIT);
                this.injector.get(service).search(data, (response)=>{
                    me.executeResponseLoadOption(response, timeout)
                })
            }
        }
    }

    executeResponseLoadOption(response, timeout?){
        let me = this;
        response.content = response.content || [];
        if(me.typeAppend == "clear"){
            me.options = response.content;
        }else if(me.typeAppend == "head"){
            me.options = [...response.content, ...me.options];
            if(me.options.length > me.maxSizeCache){
                me.options.splice(100, me.maxSizeCache - me.maxSizeCache);
            }
            me.eRef.nativeElement.querySelector(".box-item").scrollTop = response.content.length * me.heightRow;
        }else if(me.typeAppend == "foot"){
            me.options = [...me.options, ...response.content];
            if(me.options.length > me.maxSizeCache){
                me.options.splice(0, me.options.length - me.maxSizeCache);
            }
        }
        if(me.isFilterLocal){
            me.optionOrigins = response.content || [];
        }
        me.totalPage = response.totalPages;
        if(me.firstLoadValue){
            me.loadValue();
            me.firstLoadValue = false;
        }
        me.isLoadingItem = false;
        if(timeout){
            clearTimeout(timeout);
        }
    }

    getSizeCoverBoxItem(){
        let boxItem: Element = this.eRef.nativeElement.querySelector(".box-item");
        let boxEmpty: Element = this.eRef.nativeElement.querySelector(".box-item-empty");
        if(boxItem){
            let style = {
                top: boxItem["offsetTop"] + "px",
                left: boxItem["offsetLeft"] + "px",
                height: boxItem.clientHeight + "px",
                width: boxItem.clientWidth + "px"
            }
            return style;
        }else if(boxEmpty){
            let style = {
                top: boxEmpty["offsetTop"] + "px",
                left: boxEmpty["offsetLeft"] + "px",
                height: boxEmpty.clientHeight + "px",
                width: boxEmpty.clientWidth + "px"
            }
            return style;
        }
        return {
            top: "0px",
            left: "0px",
            height: "190px",
            width: "100%"
        };
    }

    checkEmptyValue(){
        let str = JSON.stringify(this.value).trim();
        return str.length == 0 || str == "{}" || str == "[]";
    }

    loadValue(updateDisplay:boolean = true){
        this.updateValidate();
        if(updateDisplay){
            if(this.options.length == 0) return;
        }
        let me = this;
        this.listChoice = [];
        if(this.value != null && this.value != undefined && !this.checkEmptyValue()){
            if(this.isAutoComplete == false && this.isMultiChoice){
                if(this.typeValue == "primitive"){
                    this.listChoice = [...this.value];
                }else{
                    this.listChoice = this.value.map(el => el[me.keyReturn]);
                }
            }else{
                if(this.typeValue == "primitive"){
                    this.listChoice = [this.value];
                }else{
                    this.listChoice = [this.value[this.keyReturn]];
                }
            }
        }
        if(this.listChoice.length > 0) {
            if(this.isAutoComplete == false && this.isMultiChoice == true){
                if(updateDisplay == true){
                    this.listObjectDisplay = this.options.filter(el => me.listChoice.includes(el[me.keyReturn]));
                    if(this.listObjectDisplay.length <= this.maxDisplay && this.listObjectDisplay.length < this.listChoice.length){
                        let arr = this.listObjectDisplay.map(el => el[me.keyReturn]);
                        this.listChoice.forEach(el => {
                            if(!arr.includes(el)){
                                this.getObjectChoiceBonus(el)
                            }
                        })
                    }
                }
            }else{
                if(updateDisplay == true){
                    this.objectChoice = null;
                    for(let i = 0;i < this.options.length;i++){
                        if(this.options[i][me.keyReturn] == this.listChoice[0]){
                            this.objectChoice = this.options[i];
                        }
                    }
                    if(this.objectChoice == null){
                        this.getObjectChoiceBonus(this.listChoice[0])
                    }
                }
            }
        }else{
            this.objectChoice = null;
            this.listObjectDisplay = [];
        }
    }

    getObjectChoiceBonus(value){
        if(this.isAutoComplete){
            return;
        }
        let me = this;
        let service = this.getService();
        if(service != null){
            if(service.keyId == this.keyReturn){
                if(value != undefined) {
                    this.injector.get(service.service).getById(value, (response)=>{
                        if(response){
                            if(me.isAutoComplete == false && me.isMultiChoice == true){
                                me.listObjectDisplay = [...me.listObjectDisplay, response];
                            }else{
                                me.objectChoice = response;
                            }
                        }
                    })
                }
            }else{
                let valueSearch = value + "";
                if(service.hasBase64){
                    valueSearch = me.utilService.stringToStrBase64(valueSearch);
                }
                this.injector.get(service.service).getByKey(this.keyReturn, valueSearch, (response)=>{
                    if(response && response.length > 0){
                        if(me.isAutoComplete == false && me.isMultiChoice){
                            me.listObjectDisplay = [...me.listObjectDisplay, response[0]];
                        }else{
                            me.objectChoice = response[0];
                        }
                    }
                })
            }
        }
    }

    load(){
        if(this.isAutoComplete == false && this.isMultiChoice == true){
            this.oldValue = [...(this.value || [])];
        }else{
            if(this.typeValue == "primitive"){
                this.oldValue == this.value;
            }else{
                this.oldValue = {...(this.value || {})};
            }
        }
        this.optionOld = [...this.options];
        this.isCallApi = this.isAutoComplete == true || this.lazyLoad == true;
        if(this.isCallApi == true){
            this.loadOption();
        }else{
            this.optionOrigins = [...this.options];
            this.loadValue();
        }
    }

    getTextItemDisplay(item){
        if(item == null){
            if(this.isAutoComplete){
                return this.value;
            }else return "";
        }
        let startGetkey = false;
        let key = "";
        let result = "";
        for(let i = 0; i < this.displayPattern.length; i++){
            if(this.displayPattern[i] == "$"){
                if(this.displayPattern[i+1] == "{"){
                    startGetkey = true;
                    i = i + 1;
                }
            }else if(startGetkey == true){
                if(this.displayPattern[i] == "}"){
                    result += ((item[key] || "") + "").trim();
                    key = "";
                    startGetkey = false;
                }else{
                    key += this.displayPattern[i];
                }
            }else{
                result += this.displayPattern[i];
            }
        }
        return result;
    }

    getStyleBoxSelect(){
        if(this.stylePositionBoxSelect) return this.stylePositionBoxSelect;
        let target:Element = this.eRef.nativeElement;
        let totalWidth = document.body.offsetWidth;
        let positionLeft = target["offsetLeft"];
        let positionRight = target["offsetLeft"] + target.getBoundingClientRect().width;
        let positionTop = target["offsetTop"];
        let targetWidth = target.getBoundingClientRect().width;
        let width = document.getElementsByClassName("box-select")[0]["offsetWidth"];

        let style = {};
        if(positionLeft + width > totalWidth - 50){
            style["right"] = (totalWidth - positionRight) + "px"
        }else{
            style["left"] = positionLeft + "px";
        }
        style["min-width"] = targetWidth + "px";
        style["top"] = (positionTop + 40) + "px";
        return style;
    }

    changeValueList(item){
        let me = this;
        let valueSelected = this.listObjectDisplay.map(el => el[me.keyReturn]);
        let oldSelectedInOptions = this.options.filter(el => valueSelected.includes(el[me.keyReturn])).map(el => el[me.keyReturn]);
        this.listObjectDisplay = [...this.options.filter(el => this.listChoice.includes(el[me.keyReturn])), ...this.listObjectDisplay.filter(el => !oldSelectedInOptions.includes(el[me.keyReturn]))];
        this.emitValue();
        this.focusRow(item);
    }

    toggeSelectRow(event, item){
        if(this.disabled) return;
        let me = this;
        if(this.isAutoComplete == false && this.isMultiChoice == true){
            if(this.listChoice.includes(item[this.keyReturn])){
                this.listChoice = this.listChoice.filter(el => el != item[me.keyReturn]);
                this.listObjectDisplay = this.listObjectDisplay.filter(el => el[me.keyReturn] != item[me.keyReturn]);
            }else {
                this.listChoice = [...this.listChoice, item[this.keyReturn]];
                this.listObjectDisplay.push(item);
            }
            this.focusRow(item);
        }else{
            this.objectChoice = item;
            this.isShowBoxSelect = false;
            if(this.isAutoComplete == true){
                this.valueFilter = this.getTextItemDisplay(this.objectChoice);
            }
        }
        let input = this.eRef.nativeElement.querySelector("input.inputText");
        if(input){
            input.focus();
        }else{
            this.eRef.nativeElement.querySelector("input.inputTextHidden").focus();
        }
        this.emitValue();
    }

    focusRow(item){
        let me = this;
        let itemNodes:Element[] = this.eRef.nativeElement.getElementsByClassName("item-select");
        for(let i = 0; i < itemNodes.length; i ++){
            let nodeItem = itemNodes[i];
            if(nodeItem.className.indexOf("item-select-focus") >= 0){
                nodeItem.classList.remove("item-select-focus");
            }
            if(nodeItem['key'] == item[me.keyReturn]){
                nodeItem.classList.add("item-select-focus");
            }
        }
    }

    toggleSelectAll(){
        if(this.isSelectAll == false){
            this.listChoice = this.options.map(el => el[this.keyReturn]);
            this.listObjectDisplay = this.options;
        }else{
            this.listChoice = [];
            this.listObjectDisplay = [];
        }
        this.emitValue();
    }

    checkSelectAll():boolean{
        let me = this;
        let listValue = this.listObjectDisplay.map(el => el[me.keyReturn]);
        for(let i = 0; i < this.options.length; i++){
            if(!listValue.includes(this.options[i][this.keyReturn])){
                return false;
            }
        }
        return true;
    }

    emitValue(){
        if(this.isAutoComplete){
            if(this.objectChoice == null){
                this.value = this.valueFilter;
                this.oldValue = this.valueFilter;
            }else{
                if(this.typeValue == "primitive"){
                    this.value = this.objectChoice[this.keyReturn];
                    this.oldValue = this.value;
                }else{
                    this.value = {...this.objectChoice};
                    this.oldValue = {...this.objectChoice};
                }
            }
        }else if(this.isAutoComplete == false && this.isMultiChoice == true){
            this.isSelectAll = this.checkSelectAll();
            if(this.typeValue == "primitive"){
                this.value = this.listObjectDisplay.map(el => el[this.keyReturn]);
            }else{
                this.value = [...this.listObjectDisplay];
            }
            this.oldValue = [...this.value];
        }else{
            if(this.objectChoice == null){
                this.value = null;
            }else{
                if(this.typeValue == "primitive"){
                    this.value = this.objectChoice[this.keyReturn];
                    this.oldValue = this.value;
                }else{
                    this.value = {...this.objectChoice};
                    this.oldValue = {...this.objectChoice};
                }
            }
        }
        this.valueChange.emit(this.value);
        this.onchange.emit(this.value);
        this.control.dirty = true;
        this.updateValidate();
    }

    controlByKey(event){
        if(this.isLoadingItem) return;
        let node: Element = this.eRef.nativeElement.querySelector(".item-select-focus");
        let boxItem: Element = this.eRef.nativeElement.querySelector(".box-item");
        let boxFilter: Element = this.eRef.nativeElement.querySelector(".box-filter");
        let heighFilter = 0;
        if(boxFilter){
            heighFilter = boxFilter.getBoundingClientRect().height;
        }
        if(event.keyCode == 13){//enter
            if(boxItem == null){
                return;
            }
            let id = node["key"];
            let item = null;
            for(let i = 0; i < this.options.length; i++){
                if(this.options[i][this.keyReturn] == id){
                    item = this.options[i];
                    break;
                }
            }
            this.toggeSelectRow(null, item);
        }else if(event.keyCode == 40){//xuong
            if(boxItem == null){
                if(this.isShowBoxSelect == false){
                    this.isShowBoxSelect = true;
                }
                let input = this.eRef.nativeElement.querySelector("input.inputText");
                if(input){
                    input.focus();
                }else{
                    this.eRef.nativeElement.querySelector("input.inputTextHidden").focus();
                }
                return;
            }
            let nodeNext: Element = node.parentElement.nextElementSibling
            if(nodeNext != null){
                nodeNext = nodeNext.firstElementChild;
                let top = nodeNext["offsetTop"] - heighFilter;
                if(top - boxItem["scrollTop"] > this.heightRow * 4){
                    boxItem["scrollTop"] = top - this.heightRow * 4;
                }
                node.classList.remove("item-select-focus");
                nodeNext.classList.add("item-select-focus");
            }
        }else if(event.keyCode == 38){//len
            if(boxItem == null){
                return;
            }
            let nodePrevious: Element = node.parentElement.previousElementSibling;
            if(nodePrevious != null){
                nodePrevious = nodePrevious.firstElementChild;
                let top = nodePrevious["offsetTop"] - heighFilter;
                if(top - boxItem["scrollTop"] < 0){
                    boxItem["scrollTop"] = top;
                }
                node.classList.remove("item-select-focus");
                nodePrevious.classList.add("item-select-focus")
            }
        }
    }

    clearFilter(){
        this.valueFilter = "";
        // this.isShowBoxSelect = false;
        this.page = 0;
        this.typeAppend = "clear";
        if(this.isCallApi){
            this.debounceService.set("filter-combobox-lazy", this.loadOption.bind(this));
        }else{
            this.debounceService.set("filter-combobox-lazy", this.filterLocal.bind(this));
        }
    }

    clearValue(){
        if(this.isAutoComplete == false){
            if(this.isMultiChoice){
                this.listChoice = [];
                this.listObjectDisplay = [];
            }else{
                this.objectChoice = null;
            }
            this.emitValue();
        }
    }

    controlScroll(event){
        if(!this.isCallApi) return;
        let node: Element = this.eRef.nativeElement.querySelector(".box-item");
        let scrollTop = node.scrollTop;
        let minPageCurrent = this.getMinPage();
        let maxPageCurrent = this.getMaxPage();
        if(scrollTop == 0 && minPageCurrent > 0){
            this.page = minPageCurrent - 1;
            this.typeAppend = "head";
            this.loadOption();
        }else if(scrollTop == this.options.length * this.heightRow - this.heightRow * 5 && maxPageCurrent < this.totalPage - 1){
            this.page = maxPageCurrent + 1;
            this.typeAppend = "foot";
            this.loadOption();
        }
    }

    getMinPage():number{
        if(this.typeAppend == "clear" || this.typeAppend == "head"){
            return this.page;
        }else{
            return this.page - (this.options.length % this.size == 0 ? (Math.round(this.options.length / this.size) - 1) : Math.floor(this.options.length / this.size));
        }
    }

    getMaxPage():number{
        if(this.typeAppend == "clear" || this.typeAppend == "foot"){
            return this.page;
        }else{
            return this.page + (this.options.length % this.size == 0 ? (Math.round(this.options.length / this.size) - 1) : Math.floor(this.options.length / this.size));
        }
    }

    openMoreAction(event, opViewListChoice){
        opViewListChoice.toggle(event);
        this.isShowBoxSelect=false;
        event.stopPropagation();
    }

    openViewListChoice(){
        this.isShowDialogViewListChoice = true;
    }
}