<div class="mt-2 table-qlda relative w-full">
    <p-table
        [value]="listObject"
        [tableStyle]="{ 'width': '100%' }"
        [styleClass]="'p-datatable-sm'"
        #dataTable
        [scrollHeight]="scrollHeight"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let item of columns" class="white-space-nowrap"
                    [style]="{'min-width': item.size, 'text-align': item.align}"
                >{{ item.name }}</th>
                <th *ngIf="options.mode != objectMode.DETAIL" style="text-align: center;" class="white-space-nowrap">{{tranService.translate("global.text.action")}}</th>
            </tr>
            <tr *ngIf="checkEmpty() && getColspan() > 0"> 
                <td [attr.colspan]="getColspan()" [style]="{'min-width': columns[0].size}" class="box-table-nodata">
                    <span class="pi pi-inbox" style="font-size: x-large;">&nbsp;</span>{{tranService.translate("global.text.nodata")}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-i="rowIndex">
            <tr *ngIf="data[fieldId] != 0 && data[fieldId] != -1 && data.mode == objectMode.DETAIL">
                <td *ngFor="let item of columns" (dblclick)="editRow(data[fieldId], data)"
                    [style]="{'min-width': item.size,'width':item.size,'text-align':item.align}" 
                    [title]="item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key]" 
                    class="table-column-qlda"
                >
                    <span *ngIf="!item.funcGetRouting" (click)="handleClickText(item, data)"
                        [style]="item.style" 
                        [class]="item.funcGetClassname ? item.funcGetClassname(data[item.key]) : item.className"
                    >{{ item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key] }}</span>
                    <a [routerLink]="item.funcGetRouting(data)" 
                        routerLinkActive="router-link-active" 
                        *ngIf="item.funcGetRouting" 
                        [style]="item.style" 
                        [class]="item.funcGetClassname ? item.funcGetClassname(data[item.key]) : item.className"
                    >{{ item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key] }}</a>
                </td>
                <td *ngIf="options.mode != objectMode.DETAIL">
                    <div class="flex flex-row grap-2 justify-content-center align-items-center">
                        <span *ngFor="let op of filterAction(options.action, data, data[fieldId])"
                            class="inline-block mr-1 cursor-pointer"
                            [class]="op.icon"
                            (click)="op.func(data[fieldId], data)"
                            [pTooltip]="op.tooltip"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-arrow-up" *ngIf="showMove && i > 0"
                            (click)="pushUp(i, data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.pushUp')"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-arrow-down" *ngIf="showMove && i < (listObject?.length - 3)"
                            (click)="pushDown(i, data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.pushDown')"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-trash"
                            (click)="removeItem(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.delete')"
                            tooltipPosition="left"
                        ></span>
                    </div>
                </td>
            </tr>
            <tr *ngIf="data[fieldId] != 0 && data[fieldId] != -1 && data.mode == objectMode.UPDATE && formEdit" [formGroup]="formEdit">
                <td *ngFor="let item of columns" 
                    [style]="{'min-width': item.size,'width':item.size,'text-align':item.align}" 
                    [title]="item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key]" 
                    class="table-column-qlda"
                >  
                    <span *ngIf="item.allowUpdate == false">
                        <span *ngIf="!item.funcGetRouting" (click)="handleClickText(item, data)"
                            [style]="item.style" 
                            [class]="item.funcGetClassname ? item.funcGetClassname(data[item.key]) : item.className"
                        >{{ item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key] }}</span>
                        <a [routerLink]="item.funcGetRouting(data)" 
                            routerLinkActive="router-link-active" 
                            *ngIf="item.funcGetRouting" 
                            [style]="item.style" 
                            [class]="item.funcGetClassname ? item.funcGetClassname(data[item.key]) : item.className"
                        >{{ item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key] }}</a>
                    </span>
                    <span *ngIf="item.allowUpdate == true">
                        <input [style]="{'width': item.size}" *ngIf="item.type == objectType.STRING" type="text" pInputText [(ngModel)]="objectEdit[item.key]"
                            [formControlName]="item.key"
                            [required]="item.validate.required"
                            [pattern]="item.validate.pattern"
                            [maxLength]="item.validate.maxLength"
                            [minlength]="item.validate.minLength"
                            [placeholder]="tranService.translate('global.text.inputText')"
                            (ngModelChange)="checkExists(item)"
                            [class]="mapErrorExists[item.key] == true ? 'ng-invalid ng-dirty': ''"
                        />
                        <p-inputNumber [inputStyle]="{'width': item.size}" *ngIf="item.type == objectType.NUMBER" [(ngModel)]="objectEdit[item.key]"
                                        [formControlName]="item.key"
                                        [required]="item.validate.required"
                                        [min]="item.validate.min"
                                        [max]="item.validate.max"
                                        [placeholder]="tranService.translate('global.text.inputNumber')"
                        ></p-inputNumber>
                        <p-calendar [style]="{'width': item.size}" *ngIf="item.type == objectType.DATE"
                            [(ngModel)]="objectEdit[item.key]"
                            [showClear]="true"
                            [showIcon]="true"
                            dateFormat="dd/mm/yy"
                            [formControlName]="item.key" appendTo="body"
                            [required]="item.validate.required"
                            [maxDate]="item.validate.maxDate"
                            [minDate]="item.validate.minDate"
                            [placeholder]="tranService.translate('global.text.inputDate')"
                        />
                        <p-calendar [style]="{'width': item.size}" *ngIf="item.type == objectType.TIMESTAMP"
                            [(ngModel)]="objectEdit[item.key]"
                            dateFormat="dd/mm/yy"
                            hourFormat="hh:mm:ss"
                            [showTime]="true" [showSeconds]="true"
                            [formControlName]="item.key"
                            [showClear]="true" [showIcon]="true" appendTo="body"
                            [required]="item.validate.required"
                            [maxDate]="item.validate.maxDate"
                            [minDate]="item.validate.minDate"
                            [placeholder]="tranService.translate('global.text.inputTimestamp')"
                        />
                        <p-dropdown [style]="{'width': item.size}" *ngIf="item.type == objectType.LIST_NUMBER || item.type == objectType.LIST_STRING"
                                [showClear]="true"
                                [autoDisplayFirst]="false"
                                [(ngModel)]="objectEdit[item.key]"
                                [options]="item.valueList"
                                [optionLabel]="item.optionLabel"
                                [optionValue]="item.optionValue"
                                [formControlName]="item.key" appendTo="body"
                                [required]="item.validate.required"
                                [placeholder]="tranService.translate('global.text.selectValue')"
                        ></p-dropdown>
                        <div class="w-full mt-1">
                            <small class="text-red-500" *ngIf="formEdit.controls[item.key].dirty && formEdit.controls[item.key].errors?.required">{{tranService.translate("global.message.required")}}</small>
                            <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.maxLength && !formEdit.controls[item.key].errors?.pattern">{{tranService.translate("global.message.maxLength",{len:item.validate.maxLength})}}</small>
                            <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.minlength && !formEdit.controls[item.key].errors?.pattern">{{tranService.translate("global.message.minLength",{len:item.validate.minLength})}}</small>
                            <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.max">{{tranService.translate("global.message.max",{value:item.validate.max})}}</small>
                            <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.min">{{tranService.translate("global.message.min",{value:item.validate.min})}}</small>
                            <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.pattern">{{item.validate.messageErrorPattern}}</small>
                            <small class="text-red-500" *ngIf="mapErrorExists[item.key] == true">{{tranService.translate("global.message.duplicated")}}</small>
                            <small class="text-red-500" *ngIf="mapErrorExists[item.key] == false || !formEdit.controls[item.key].invalid || formEdit.controls[item.key].dirty">&nbsp;</small>
                        </div>
                    </span>
                </td>
                <td>
                    <div class="flex flex-row grap-2 justify-content-center align-items-center">
                        <span class="inline-block mr-1 cursor-pointer pi pi-replay"
                            (click)="resetForm(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.reset')"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-send" *ngIf="!checkInvalidValidForm()"
                            (click)="saveObject(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.save')"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-times"
                            (click)="cancel(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.cancel')"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-trash"
                            (click)="removeItem(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.delete')"
                            tooltipPosition="left"
                        ></span>
                    </div>
                    <div class="w-full mt-1" style="width: 100%;">
                        <small>&nbsp;</small>
                    </div>
                </td>
            </tr>
            <tr *ngIf="data[fieldId] == -1 && data.mode == objectMode.CREATE && formEdit" [formGroup]="formEdit">
                <td *ngFor="let item of columns" 
                    [style]="{'min-width': item.size,'width':item.size,'text-align':item.align}" 
                    [title]="item.funcConvertText ? item.funcConvertText(data[item.key]) : data[item.key]" 
                    class="table-column-qlda"
                >
                    <input [style]="{'width': item.size}" *ngIf="item.type == objectType.STRING" type="text" pInputText [(ngModel)]="objectEdit[item.key]"
                            [formControlName]="item.key"
                            [required]="item.validate.required"
                            [pattern]="item.validate.pattern"
                            [maxLength]="item.validate.maxLength"
                            [minlength]="item.validate.minLength"
                            [placeholder]="tranService.translate('global.text.inputText')"
                            (ngModelChange)="checkExists(item)"
                            [class]="mapErrorExists[item.key] == true ? 'ng-invalid ng-dirty': ''"
                    />
                    <p-inputNumber [inputStyle]="{'width': item.size}" *ngIf="item.type == objectType.NUMBER" [(ngModel)]="objectEdit[item.key]"
                                    [formControlName]="item.key"
                                    [required]="item.validate.required"
                                    [min]="item.validate.min"
                                    [max]="item.validate.max"
                                    [placeholder]="tranService.translate('global.text.inputNumber')"
                    ></p-inputNumber>
                    <p-calendar [style]="{'width': item.size}" *ngIf="item.type == objectType.DATE"
                        [(ngModel)]="objectEdit[item.key]"
                        [showClear]="true"
                        [showIcon]="true"
                        dateFormat="dd/mm/yy"
                        [formControlName]="item.key" appendTo="body"
                        [required]="item.validate.required"
                        [maxDate]="item.validate.maxDate"
                        [minDate]="item.validate.minDate"
                        [placeholder]="tranService.translate('global.text.inputDate')"
                    />
                    <p-calendar [style]="{'width': item.size}" *ngIf="item.type == objectType.TIMESTAMP"
                        [(ngModel)]="objectEdit[item.key]"
                        dateFormat="dd/mm/yy"
                        hourFormat="hh:mm:ss"
                        [showTime]="true" [showSeconds]="true"
                        [formControlName]="item.key"
                        [showClear]="true" [showIcon]="true" appendTo="body"
                        [required]="item.validate.required"
                        [maxDate]="item.validate.maxDate"
                        [minDate]="item.validate.minDate"
                        [placeholder]="tranService.translate('global.text.inputTimestamp')"
                    />
                    <p-dropdown [style]="{'width': item.size}" *ngIf="item.type == objectType.LIST_NUMBER || item.type == objectType.LIST_STRING"
                            [showClear]="true"
                            [autoDisplayFirst]="false"
                            [(ngModel)]="objectEdit[item.key]"
                            [options]="item.valueList"
                            [optionLabel]="item.optionLabel"
                            [optionValue]="item.optionValue"
                            [formControlName]="item.key" appendTo="body"
                            [required]="item.validate.required"
                            [placeholder]="tranService.translate('global.text.selectValue')"
                    ></p-dropdown>
                    <div class="w-full mt-1">
                        <small class="text-red-500" *ngIf="formEdit.controls[item.key].dirty && formEdit.controls[item.key].errors?.required">{{tranService.translate("global.message.required")}}</small>
                        <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.maxLength && !formEdit.controls[item.key].errors?.pattern">{{tranService.translate("global.message.maxLength",{len:item.validate.maxLength})}}</small>
                        <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.minlength && !formEdit.controls[item.key].errors?.pattern">{{tranService.translate("global.message.minLength",{len:item.validate.minLength})}}</small>
                        <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.max">{{tranService.translate("global.message.max",{value:item.validate.max})}}</small>
                        <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.min">{{tranService.translate("global.message.min",{value:item.validate.min})}}</small>
                        <small class="text-red-500" *ngIf="formEdit.controls[item.key].errors?.pattern">{{item.validate.messageErrorPattern}}</small>
                        <small class="text-red-500" *ngIf="mapErrorExists[item.key] == true">{{tranService.translate("global.message.duplicated")}}</small>
                        <small class="text-red-500" *ngIf="mapErrorExists[item.key] == false || !formEdit.controls[item.key].invalid || formEdit.controls[item.key].dirty">&nbsp;</small>
                    </div>
                </td>
                <td>
                    <div class="flex flex-row grap-2 justify-content-center align-items-center">
                        <span class="inline-block mr-1 cursor-pointer pi pi-replay"
                            (click)="resetForm(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.reset')"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-send" *ngIf="!checkInvalidValidForm()"
                            (click)="saveObject(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.save')"
                            tooltipPosition="left"
                        ></span>
                        <span class="inline-block mr-1 cursor-pointer pi pi-trash"
                            (click)="removeItem(data[fieldId], data)"
                            [pTooltip]="tranService.translate('global.button.delete')"
                            tooltipPosition="left"
                        ></span>
                    </div>
                    <div class="w-full mt-1" style="width: 100%;">
                        <small>&nbsp;</small>
                    </div>
                </td>
            </tr>
            <tr *ngIf="data[fieldId] == 0 && !control.isUpdating && options.mode != objectMode.DETAIL">
                <td [attr.colspan]="getColspan()" style="text-align: left;">
                    <span class="pi pi-plus cursor-pointer" style="font-size: large;" [pTooltip]="tranService.translate('global.button.create')" (click)="createNew()"></span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>