import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class DebounceInputService {
    private mapCallback = {};

    public set(key: string, callback: Function, ...args:any){
        if(key in this.mapCallback){
            clearTimeout(this.mapCallback[key]);
        }
        this.mapCallback[key] = setTimeout(function(){
            if(args){
                callback(...args);
            }else{
                callback();
            }
        },500);
    }

    public clear(){
        let me = this;
        Object.keys(this.mapCallback).forEach(key => {
            clearTimeout(me.mapCallback[key]);
        });
    }
}