<div class="qlda flex flex-row justify-content-between align-items-center bg-white p-2 border-round">
    <div class="">
        <div class="text-xl font-bold mb-1">{{this.tranService.translate("global.text.accountInfo")}}</div>
        <p-breadcrumb class="max-w-full col-7" [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <div class="col-5 flex flex-row justify-content-end align-items-center">
        
    </div>
</div>

<p-card styleClass="mt-3">
    <div *ngIf="studentForm">
        <form [formGroup]="studentForm" (ngSubmit)="onSubmitCreate()">
            <div class="flex flex-row justify-content-between">
                <div style="width: 69%;">
                    <!-- name -->
                    <div class="w-full field grid">
                        <label htmlFor="studentName" class="col-fixed" style="width:180px">{{tranService.translate("account.label.name")}}<span class="text-red-500">*</span></label>
                        <div class="col">
                            <input class="w-full"
                                    pInputText id="studentName"
                                    [(ngModel)]="student.name"
                                    formControlName="name"
                                    pattern="^[a-zA-Z0-9 _\u00C0-\u1EF9]+$"
                                    [required]="true"
                                    [maxLength]="255"
                                    [placeholder]="tranService.translate('account.text.inputStudentName')"
                            />
                        </div>
                    </div>
                    <!-- error student name -->
                    <div class="w-full field grid text-error-field">
                        <label htmlFor="projectName" class="col-fixed" style="width:180px"></label>
                        <div class="col">
                            <small class="text-red-500" *ngIf="studentForm.controls.name.dirty && studentForm.controls.name.errors?.required">{{tranService.translate("global.message.required")}}</small>
                            <small class="text-red-500" *ngIf="studentForm.controls.name.errors?.maxLength">{{tranService.translate("global.message.maxLength",{len:255})}}</small>
                            <small class="text-red-500" *ngIf="studentForm.controls.name.errors?.pattern">{{tranService.translate("global.message.formatContainVN")}}</small>
                        </div>
                    </div>
                    <!-- email -->
                    <div class="w-full field grid">
                        <label htmlFor="email" class="col-fixed" style="width:180px">{{tranService.translate("account.label.email")}}<span class="text-red-500">*</span></label>
                        <div class="col">
                            <input class="w-full"
                                    pInputText id="email"
                                    [(ngModel)]="student.email"
                                    formControlName="email"
                                    [required]="true"
                                    [maxLength]="255"
                                    pattern="^[a-z0-9]+[a-z0-9\-\._]*[a-z0-9]+@([a-z0-9]+[a-z0-9\-\._]*[a-z0-9]+)+(\.[a-z]{2,})$"
                                    [placeholder]="tranService.translate('account.text.inputEmail')"
                                    (ngModelChange)="checkExistAccount('email')"
                            />
                        </div>
                    </div>
                    <!-- error email -->
                    <div class="w-full field grid text-error-field">
                        <label htmlFor="email" class="col-fixed" style="width:180px"></label>
                        <div class="col">
                            <small class="text-red-500" *ngIf="studentForm.controls.email.dirty && studentForm.controls.email.errors?.required">{{tranService.translate("global.message.required")}}</small>
                            <small class="text-red-500" *ngIf="studentForm.controls.email.errors?.maxLength">{{tranService.translate("global.message.maxLength",{len:255})}}</small>
                            <small class="text-red-500" *ngIf="studentForm.controls.email.errors?.pattern">{{tranService.translate("global.message.invalidEmail")}}</small>
                            <small class="text-red-500" *ngIf="isEmailExisted">{{tranService.translate("global.message.exists",{type: tranService.translate("account.label.email").toLowerCase()})}}</small>
                        </div>
                    </div>
                    <!-- phone -->
                    <div class="w-full field grid">
                        <label htmlFor="phone" class="col-fixed" style="width:180px">{{tranService.translate("account.label.phone")}}<span class="text-red-500">*</span></label>
                        <div class="col">
                            <input class="w-full"
                                    pInputText id="phone"
                                    [(ngModel)]="student.phone"
                                    formControlName="phone"
                                    [required]="true"
                                    pattern="^((\+?[1-9][0-9])|0?)[1-9][0-9]{8,9}$"
                                    [placeholder]="tranService.translate('account.text.inputPhone')"
                                    (ngModelChange)="checkExistAccount('phone')"
                            />
                        </div>
                    </div>
                    <!-- error phone -->
                    <div class="w-full field grid text-error-field">
                        <label htmlFor="phone" class="col-fixed" style="width:180px"></label>
                        <div class="col">
                            <small class="text-red-500" *ngIf="studentForm.controls.phone.dirty && studentForm.controls.phone.errors?.required">{{tranService.translate("global.message.required")}}</small>
                            <small class="text-red-500" *ngIf="studentForm.controls.phone.errors?.pattern">{{tranService.translate("global.message.invalidPhone")}}</small>
                            <small class="text-red-500" *ngIf="isPhoneExisted">{{tranService.translate("global.message.exists",{type: tranService.translate("account.label.phone").toLowerCase()})}}</small>
                        </div>
                    </div>
                    <!-- khoa hoc -->
                <div class="w-full field grid">
                    <label for="khoaHocId" class="col-fixed" style="width:180px">{{tranService.translate("project.label.course")}}</label>
                    <div class="col">
                        <p-dropdown styleClass="w-full"
                            [showClear]="false"
                            id="khoaHocId" [autoDisplayFirst]="false"
                            [(ngModel)]="student.khoaHocId"
                            formControlName="khoaHocId"
                            [options]="listCourse"
                            optionLabel="name"
                            optionValue="id"
                            (ngModelChange)="viewDetailCourse()"
                        ></p-dropdown>
                    </div>
                </div>
                <!-- nien khoa -->
                <div class="w-full field grid">
                    <label for="nienKhoaId" class="col-fixed" style="width:180px">{{tranService.translate("account.label.yearCourse")}}</label>
                    <div class="col">
                        <span>{{student.nienKhoaName}}</span>
                    </div>
                </div>
                    <!-- error linh vuc -->
                    <div class="w-full field grid text-error-field">
                        <label htmlFor="nienKhoaId" class="col-fixed" style="width:180px"></label>
                        <div class="col">
                            <small class="text-red-500" *ngIf="studentForm.controls.nienKhoaId.dirty && studentForm.controls.nienKhoaId.errors?.required">{{tranService.translate("global.message.required")}}</small>
                        </div>
                    </div>
                </div>
                <div style="width: 29%;">
                    
                </div>
            </div>
            <div class="flex flex-row justify-content-between" *ngIf="detailCourse">
                <div style="width: 69%;">
                    <p-divider [align]="'center'" type="dotted"><b>{{tranService.translate('global.menu.courseInfo')}}</b></p-divider>
                    <div class="w-full field grid">
                        <label htmlFor="courseName" class="col-fixed" style="width:180px">{{tranService.translate("account.label.courseName")}}</label>
                        <div class="col">
                            <span>{{detailCourse.khoaHocName}}</span>
                        </div>
                    </div>
                    <div class="w-full field grid" *ngIf="detailCourse.status == -1">
                        <label htmlFor="courseName" class="col-fixed" style="width:180px">{{tranService.translate("account.label.status")}}</label>
                        <div class="col">
                            <span>{{tranService.translate('account.text.statusNonRegister')}}</span>
                        </div>
                    </div>
                    <div class="w-full field grid" *ngIf="detailCourse.status != -1">
                        <label htmlFor="courseName" class="col-fixed" style="width:180px">{{tranService.translate("project.label.name")}}</label>
                        <div class="col">
                            <span>{{detailCourse.projectName}}</span>
                        </div>
                    </div>
                    <div class="w-full field grid" *ngIf="detailCourse.status == 0">
                        <label htmlFor="courseName" class="col-fixed" style="width:180px">{{tranService.translate("account.label.status")}}</label>
                        <div class="col">
                            <span>{{tranService.translate('account.text.statusRegistered')}}</span>
                        </div>
                    </div>
                    <div class="w-full field grid" *ngIf="detailCourse.status == 1">
                        <label htmlFor="courseName" class="col-fixed" style="width:180px">{{tranService.translate("account.label.status")}}</label>
                        <div class="col">
                            <span>{{tranService.translate('account.text.statusSubmitted')}}</span>
                        </div>
                    </div>
                </div>
                <div style="width: 29%;">
                    
                </div>
            </div>
            <div class="flex flex-row justify-content-center align-items-center">
                <p-button [label]="tranService.translate('global.button.cancel')" styleClass="p-button-secondary p-button-outlined mr-2" [routerLink]="['/students']" routerLinkActive="router-link-active" ></p-button>
                <p-button [label]="tranService.translate('global.button.save')" styleClass="p-button-info" type="submit"
                    [disabled]="studentForm.invalid || isEmailExisted || isPhoneExisted"></p-button>
            </div>
        </form>
    </div>
</p-card>