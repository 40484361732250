export default {
    menu: {
        home: "Trang chủ",
        managerStudent: "Quản lý học viên",
        managerProject: "Quản lý project",
        listProject: "Danh sách project",
        listRegisterProject: "Danh sách đăng ký",
        listStudent: "Danh sách học viên",
        createProject: "Tạo mới project",
        editProject: "Cập nhật project",
        updateProject: "Cập nhật project",
        detailProject: "Chi tiết project",
        createStudent: "Tạo mới học viên",
        editStudent: "Cập nhật học viên",
        updateStudent: "Cập nhật học viên",
        detailStudent: "Chi tiết học viên",
        courseInfo: "Thông tin khóa học",
        student: "Học viên",
        project: "Project",
    },
    button: {
        edit: "Cập nhật",
        delete: "Xóa",
        save: "Lưu",
        cancel: "Hủy",
        close: "Đóng",
        create: "Tạo mới",
        uploadFile: "Upload file",
        download: "Tải về",
        no: "Không",
        yes: "Có",
        register: "Đăng ký",
        active: "Mở",
        inactive: "Đóng",
        submit: "Nộp bài",
        submitAgain: "Nộp bài lại",
        view: "Chi tiết",
        clear: "Xóa tất cả",
        changePass: "Đổi mật khẩu",
        removeRegister: "Bỏ đăng ký"
    },
    text: {
        textCaptcha: "Kéo sang để hoàn thành câu đố",
        filter: "Bộ lọc",
        stt: "STT",
        action: "Thao tác",
        nodata: "Không có dữ liệu",
        templateTextPagination: "Hiển thị từ {first} đến {last} của {totalRecords} bản ghi",
        page: "Trang",
        accountInfo: "Thông tin tài khoản",
        changePass: "Đổi mật khẩu",
        homepage: "Trang chủ"
    },
    message:{
        copied: "Đã sao chép",
        required: "Trường này là bắt buộc",
        requiredField: "${field} là bắt buộc",
        maxLength: "Trường này không được vượt quá ${len} ký tự",
        minLength: "Trường này không được ít hơn ${len} ký tự",
        max: "Trường này có giá trị tối đa là ${value}",
        min: "Trường này có giá trị tối thiểu là ${value}",
        duplicated: "Dữ liệu đã tồn tại",
        invalidValue: "Dữ liệu không đúng định dạng",
        formatContainVN: "Sai định dạng. Chỉ cho phép (a-z, A-Z, 0-9, . - _, dấu cách, tiếng Viết)",
        formatCode: "Sai định dạng. Chỉ cho phép (a-z, A-Z, 0-9, - _)",
        formatCodeNotSub: "Sai định dạng. Chỉ cho phép (a-z, A-Z, 0-9, _)",
        invalidEmail: "Email sai định dạng",
        formatEmail: "Định dạng email phải là abc@xyz.abc",
        invalidPhone: "Số điện thoại sai định dạng",
        exists: "Đã tồn tại ${type} này",
        success: "Thao tác thành công",
        error: "Thao tác thất bại",
        saveSuccess: "Lưu thành công",
        saveError: "Lưu thất bại",
        timeout: "Thao tác quá thời gian",
        errorMatchCaptcha: "Đặt miếng ghép vào đúng vị trí của nó",
        confirmDeleteAccount: "Bạn có chắc chắc muốn xóa tài khoản này không?",
        titleConfirmDeleteAccount: "Xóa tài khoản",
        deleteSuccess: "Xóa thành công",
        deleteFail: "Xóa thất bại",
        invalidFile: "Định dạng file không hợp lệ",
        invalidPasswordFomat : "Mật khẩu 6-20 ký tự, bao gồm ít nhất 1 chữ cái và 1 số và 1 ký tự đặc biệt",
        passwordNotMatch: "Mật khẩu không khớp",
        wrongCurrentPassword : "Mật khẩu hiện tại sai!",
        forgotPassSendMailSuccess : "Mật khẩu khôi phục đã được gửi tới email của bạn. Xin vui lòng kiểm tra email!.",

        confirmDeleteProject: "Bạn chắc chắn muốn xóa project này?",
        titleDeleteProject: "Xóa project",
        confirmChangeActiveProject: "Bạn chắc chắn muốn đổi trạng thái project này?",
        titleChangeActiveProject: "Đổi trạng thái project",
    },
}
