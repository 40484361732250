import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class UtilService{
    public convertLongDateToString(value:number):string{
        if(value == null) return ""
        return this.convertDateToString(new Date(value));
    }

    public convertMonthToString(value: Date):string{
        if(value == null) return "";
        return this.dateToString(value);
    }

    public convertDateToString(value: Date):string{
        if(value == null) return "";
        return this.dateToString(value);
    }

    public convertNumberToString(value:number):string{
        return new Intl.NumberFormat('vn-VN', {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(value);
    }

    public convertLongDateTImeToString(value: number): string {
        if(value == null) return "";
        let date = new Date(value);
        return this.convertDateTimeToString(date);
    }

    public convertDateTimeToString(value: Date):string{
        if(value == null) return "";
        let datePart = this.dateToString(value);
        let timePart = this.hourToString(value);
        return `${datePart} ${timePart}`;
    }

    private convertStringToByte(value):any{
        return new TextEncoder().encode(value);
    }

    private convertByteToString(value):any{
        return new TextDecoder().decode(value);
    }

    public strBase64ToString(value) {
        let binString = atob(value);
        return this.convertByteToString(Uint8Array.from(binString, (m) => m.codePointAt(0)));
    }
      
    public stringToStrBase64(value) {
        let bytes = this.convertStringToByte(value);
        let binString = String.fromCodePoint(...bytes);
        return btoa(binString);
    }

    convertTextViToEnUpperCase(text:string):string{
        text = text.toUpperCase();
        let result = "";
        for(let i = 0;i<text.length;i++){
            if(["A","Á","À","Ã","Ả","Ạ","Ă","Ắ","Ằ","Ẳ","Ẵ","Ặ","Â","Ấ","Ầ","Ẩ","Ẫ","Ậ"].includes(text.charAt(i).toString())){
                result += "A";
            }else if(["E","Ê","È","Ề","É","Ế","Ẻ","Ể","Ẽ","Ễ","Ẹ","Ệ"].includes(text.charAt(i).toString())){
                result += "E"
            }else if(["I","Ì","Í","Ỉ","Ĩ","Ị"].includes(text.charAt(i).toString())){
                result += "I"
            }else if(["O","Ô","Ơ","Ò","Ồ","Ờ","Ó","Ố","Ớ","Ỏ","Ổ","Ở","Õ","Ỗ","Ỡ","Ọ","Ộ","Ợ"].includes(text.charAt(i).toString())){
                result += "O"
            }else if(["U","Ư","Ù","Ừ","Ú","Ứ","Ủ","Ử","Ũ","Ữ","Ụ","Ự"].includes(text.charAt(i).toString())){
                result += "U"
            }else if(["Y","Ỳ","Ý","Ỷ","Ỹ","Ỵ"].includes(text.charAt(i).toString())){
                result += "Y"
            }else if(["D","Đ"].includes(text.charAt(i).toString())){
                result += "D";
            }else{
                result += text.charAt(i).toString();
            }
        }
        return result;
    }

    private dateToString(value: Date){
        let day = value.getDate();
        let month = value.getMonth() + 1;
        let year = value.getFullYear();

        return `${day >=  10 ? day : '0'+day}/${month >=  10 ? month : '0'+month}/${year}`
    }

    private hourToString(value: Date){
        let hour = value.getHours();
        let min = value.getMinutes();
        let sec = value.getSeconds();
        return `${hour >=  10 ? hour : '0'+hour}:${min >=  10 ? min : '0'+min}:${sec >=  10 ? sec : '0'+sec}`
    }

    private unsecuredCopyToClipboard(text, callback?) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          if(callback){callback();}
        } catch (err) {
          console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }

    public copyToClipboard(content, callback?){
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(content).then(function(){
                if(callback){callback();}
            });
        } else {
            this.unsecuredCopyToClipboard(content, callback);
        }
    };
}
