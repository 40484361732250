export default {
    label: {
        name: "Tên học viên",
        email: "Thư điện tử",
        phone: "Số điện thoại",
        yearCourse: "Niên khóa",
        courseName: "Tên khóa học",
        status: "Trạng thái",
        fileWork: "File bài làm",
        oldPass: "Mật khẩu cũ",
        newPass: "Mật khẩu mới",
        confirmPass: "Xác nhận mật khẩu mới",
        lastUpdate: "Ngày cập nhật"
    },
    text: {
        inputStudentName: "Nhập tên học viên",
        inputEmail: "Nhập thư điện tử",
        inputPhone: "Nhập số điện thoại",
        selectYearCourse: "Chọn niên khóa",
        statusNonRegister: "Chưa đăng ký",
        statusRegistered: "Đã đăng ký",
        statusSubmitted: "Đã nộp bài",
    }
}
