import { Component, Inject, OnInit } from "@angular/core";
import { TranslateService } from "src/app/service/comon/translate.service";

@Component({
    selector: "choose-language",
    templateUrl: "./choose-language.component.html"
})
export class ChooseLanguageComponent implements OnInit{
    constructor(@Inject(TranslateService) public transService:TranslateService) {
        
    }
    lang: string;
    ngOnInit(): void {
        this.lang = localStorage.getItem("lang");
        if(this.lang === undefined || this.lang === null){
            this.lang = "vi";
            localStorage.setItem("lang", this.lang);
        }
    }

    changeLanguage(valueLang){
        localStorage.setItem("lang", valueLang);
        window.location.reload();
    }
}